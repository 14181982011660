var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[(_vm.searchProgress)?_c('div',{staticClass:"spinner-wrapper"},[_c('LoadSpinner',{staticClass:"spinner",attrs:{"id":'spinner',"text":'Loading...',"size":'60',"bgcolor":'lightgray',"speed":'0',"color":'gray'}})],1):_vm._e(),(!_vm.noResults)?_c('div',{staticClass:"slide-type-wrapper"},[_c('v-sheet',{staticClass:"chipgroup ml-n4 pl-n1",attrs:{"max-width":"92vw"}},[_c('v-chip-group',{attrs:{"show-arrows":"","mandatory":"","active-class":"primary--text"}},_vm._l((_vm.keywords),function(keyword,index){return _c('v-chip',{key:index,on:{"click":function (e) { return _vm.filterClick(e, keyword); }}},[_vm._v(" "+_vm._s(keyword)+" ")])}),1)],1)],1):_vm._e(),[(_vm.noResults && !_vm.searchProgress)?_c('div',{staticClass:"not-found-container"},[_vm._m(0),_c('div',{staticClass:"not-found-descriptions"},[_c('div',[_vm._v(_vm._s(_vm.$t('bestPracticesGuide.knowContainer.noResultsFound')))]),_c('div',{staticClass:"not-found-border"},[_vm._v(" "+_vm._s(_vm.$t('search.dontLoseHeart'))+" ")])])]):_vm._e()],(
      _vm.users.length > 0 &&
      (_vm.selectedFilter === 'All' || _vm.selectedFilter === 'People')
    )?_c('div',[_c('h2',{staticClass:"mx-11 my-3 d-flex"},[_vm._v(" "+_vm._s(_vm.$t('fingerprint.searchPage.people'))+" ")]),_c('div',{staticClass:"d-flex flex-wrap ml-8"},_vm._l((_vm.users),function(item,index){return _c('div',{key:index,staticClass:"col-sm-4 pa-2"},[_c('div',{staticClass:"d-flex",on:{"click":function($event){return _vm.handleClickDetail(item, 'User')}}},[_c('v-avatar',{attrs:{"size":"68"}},[_c('img',{attrs:{"src":"https://cdn.vuetifyjs.com/images/john.jpg","alt":"John"}})]),_c('div',{staticClass:"pl-2 pt-2"},[_c('strong',{staticClass:"user-name"},[_vm._v(_vm._s(item.display_name))]),_c('br'),_c('span',{staticClass:"user-designation"},[_vm._v(_vm._s(_vm.$t('search.someDesignation')))])])],1)])}),0),_c('div',{staticClass:"divider mt-6 ml-10 mr-10"})]):_vm._e(),(
      _vm.slides.length > 0 &&
      (_vm.selectedFilter === 'All' || _vm.selectedFilter === 'Slides')
    )?_c('div',[_c('h2',{staticClass:"mx-11 my-3 d-flex"},[_vm._v("Slides")]),_c('div',{staticClass:"d-flex flex-wrap ml-8"},_vm._l((_vm.slides),function(item,index){return _c('div',{key:index,staticClass:"slide-wrapper col-sm-3"},[_c('div',{staticClass:"slider-content-wrapper"},[_c('div',{key:_vm.cacheKey,staticClass:"image-wrapper",on:{"click":function($event){return _vm.handleClickDetail(item, 'Slide')}}},[_c('img',{attrs:{"src":"/assets/img/404.png","alt":"","height":"204px"}})]),_c('div',{staticClass:"bottom-wrapper"},[_c('div',{staticClass:"bottom-wrapper-title"},[_c('div',{staticClass:"bottom-wrapper-title-header"},[_vm._v(" "+_vm._s(item.display_name)+" ")]),_c('div',{class:("trending " + (item.isTrending ? 'show' : 'hide'))},[_c('img',{attrs:{"src":"/assets/img/trending.svg","alt":""}}),_c('div',{staticClass:"trending-title"},[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowSlideCard.trending'))+" ")])])])])])])}),0),_c('div',{staticClass:"divider mt-6 ml-10 mr-10"})]):_vm._e(),(
      _vm.learn.length > 0 &&
      (_vm.selectedFilter === 'All' || _vm.selectedFilter === 'Learn')
    )?_c('div',[_c('h2',{staticClass:"mx-11 my-3 d-flex"},[_vm._v(_vm._s(_vm.$t('profile.learn')))]),_c('div',{staticClass:"d-flex flex-wrap ml-8"},_vm._l((_vm.learn),function(item,index){return _c('div',{key:index,staticClass:"slide-wrapper col-sm-3"},[_c('div',{staticClass:"slider-content-wrapper",on:{"click":function($event){return _vm.handleClickDetail(item, 'Learn')}}},[_c('div',{key:_vm.cacheKey,staticClass:"image-wrapper"},[_c('img',{attrs:{"src":"/assets/img/404.png","alt":"","height":"204px"}})]),_c('div',{staticClass:"bottom-wrapper"},[_c('div',{staticClass:"bottom-wrapper-title"},[_c('div',{staticClass:"bottom-wrapper-title-header"},[_vm._v(" "+_vm._s(item.title.substr(0, item.title.indexOf(':')))+" ")])])])])])}),0),_c('div',{staticClass:"divider mt-6 ml-10 mr-10"})]):_vm._e(),(
      _vm.slides.length > 0 &&
      (_vm.selectedFilter === 'All' || _vm.selectedFilter === 'My Prezentations')
    )?_c('div',[_c('h2',{staticClass:"mx-11 my-3 d-flex"},[_vm._v(_vm._s(_vm.$t('search.myPrezentations')))]),_c('div',{staticClass:"d-flex flex-wrap ml-8"},_vm._l((_vm.slides),function(item,index){return _c('div',{key:index,staticClass:"slide-wrapper col-sm-3"},[_c('div',{staticClass:"slider-content-wrapper"},[_c('div',{key:_vm.cacheKey,staticClass:"image-wrapper",on:{"click":function($event){return _vm.handleClickDetail(item)}}},[_c('img',{attrs:{"src":"/assets/img/404.png","alt":"","height":"204px"}})]),_c('div',{staticClass:"bottom-wrapper"},[_c('div',{staticClass:"bottom-wrapper-title"},[_c('div',{staticClass:"bottom-wrapper-title-header"},[_vm._v(" test ")]),_c('div',{class:"trending "},[_c('div',{staticClass:"trending-title"},[_vm._v("Created on 02/12/2021")])])])])])])}),0),_c('div',{staticClass:"divider mt-6 ml-10 mr-10"})]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":"/assets/img/fingerprints-not-found.png","alt":""}})])}]

export { render, staticRenderFns }