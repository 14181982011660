<template>
  <div>
    <div class="slide-type-wrapper">
      <v-sheet class="chipgroup ml-n4 pl-n1" max-width="100%">
        <v-chip-group
          show-arrows
          mandatory
          active-class="primary"
          v-model="selectedChip"
        >
          <v-chip
            color="#21a7e0"
            outlined
            v-for="(keyword, index) in keywords"
            :key="index"
            :value="keyword.name.toLowerCase()"
            @click="() => clickChipIdeas(keyword)"
          >
            {{ $t(keyword.title) }}
          </v-chip>
        </v-chip-group>
      </v-sheet>
    </div>
    <!-- Loading -->
    <Loading v-if="selectedChip === 'slides' && searchSlides" />
    <Loading
      v-if="
        selectedChip === 'best practice library' &&
        !!(searchKnowSlides || searchProgress)
      "
    />
    <Loading
      v-if="
        (searchProgress || loading || !startLoadingSlidesFlag) &&
        selectedChip !== 'slides' &&
        selectedChip !== 'best practice library'
      "
    />
    <div class="search-wrapper">
      <!-- People -->
      <div
        v-if="
          chipsSelected.people &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        :class="
          leftSideBarStatus
            ? 'people-wrapper'
            : 'people-wrapper people-wrapper-small'
        "
      >
        <div class="top-wrapper">
          <div class="people-wrapper-title">
            {{ $t('fingerprint.searchPage.people') }}
          </div>
          <GoToFeatureLanding
            :pageType="'people'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <div class="people-items">
          <div
            class="people-item"
            v-for="(item, index) in people"
            :key="index"
            @click="gotoFingerPrint(item)"
          >
            <AudienceItem
              :itemClass="'no-padding'"
              :isInComplete="!item.fingerPrint || !item.isActive"
              :fullName="item.fullName"
              :firstName="item.firstName"
              :lastName="item.lastName"
              :profileImgS3Path="item.profileImage"
              :email="item.isGeneratedEmail ? '' : item.email"
              :creator="item.creator"
              :emailEntered="item.emailEntered"
              :ownerId="item.ownerID"
              :companyName="item.companyName"
              :isPrivate="item.isPrivate"
              :isShared="item.isShared"
              :isDifferentCompany="
                item.companyID !== currentUser.user.companyID
              "
              :isPublicLimited="item.isPublicLimited"
              :isGroup="item.isGroup"
              :status="item.isActive ? '' : 'Disabled'"
              :fingerprintType="
                item.fingerprintType
                  ? item.fingerprintType.toLowerCase()
                  : 'default'
              "
              :size="'50'"
              :isFirstResult="index === 0 ? true : false"
            />
          </div>
          <div v-if="people.length < allPeople.length" class="audience-action">
            <v-btn
              rounded
              outlined
              color="#21a7e0"
              height="32"
              @click="handleLoadMoreAudience"
            >
              {{ $t('prezentationsList.seeMoreResult') }}
            </v-btn>
          </div>
        </div>
      </div>
      <!-- End of People -->
      <!-- Slide Library -->
      <div
        v-if="chipsSelected.slides && !searchSlides"
        :class="
          leftSideBarStatus
            ? 'slides-wrapper slides-result-wrapper'
            : 'slides-wrapper slides-result-wrapper prezentation-wrapper-small'
        "
      >
        <!-- <div class="slides-wrapper-title">
          {{ $t('hybridSearchPage.slide_lib_label') }}
        </div> -->
        <div class="top-wrapper">
          <div class="slides-wrapper-title">
            {{ $t('hybridSearchPage.slide_lib_label') }}
          </div>
          <div class="slides-landing-btns">
            <GoToFeatureLanding
              :pageType="'slides'"
              :handleLandingPageClick="handleLandingPageClick"
            ></GoToFeatureLanding>
            <GoToFeatureLanding
              :pageType="'slides-collection'"
              :handleLandingPageClick="handleLandingPageClick"
            ></GoToFeatureLanding>
          </div>
        </div>
        <div class="slides-wrapper-subtitle" v-if="slides.length">
          {{ $t('prezentationsList.brandSlides') }}
        </div>
        <InlineSlides
          :favorite-slides="getFavoriteSlides"
          v-if="slides.length"
          :can-load-more="slides.length >= allSlides.length"
          :is-no-more="slides.length >= allSlides.length"
          key="brand_slides"
          slideOrigin="brand_slides"
          :load-more-slides="handleLoadMoreSlides"
          :handle-favorite="handleFavorite"
          :handle-download-from-list="handleDownloadFromList"
          :saved-query-payload="savedQueryPayload"
          :handle-detail-click="handleSlideDetailClick"
          :current-audience="
            audience ||
            currentUser.user.fingerprint || { fingerprint: 'director' }
          "
          :log-download="logDownload"
          :slides="slides"
          :items-per-row="3"
          :list-downloads="getDownloadedSlides"
          :trending-slides="trendingSlides"
          :list-image-replaced="getImageReplacedSlides"
          :listIconReplaced="getIconReplacedSlides"
          :origin="hybridSearchOrigin"
          :slides-meta-data="slideMetaData"
          :currentThemeCode="currentUser.theme.code || 'default'"
          :removeSlideOnDelete="removeSlideOnDelete"
          :downloadingSlides="downloadingSlides"
          :addingToFavSlides="addingToFavSlides"
        />
        <div class="slides-wrapper-subtitle" v-if="uploadedSlides.length">
          {{ $t('search.uploadedSlides') }}
        </div>
        <InlineSlides
          :favorite-slides="getFavoriteSlides"
          key="uploaded"
          slideOrigin="uploaded"
          v-if="uploadedSlides.length"
          :can-load-more="uploadedSlides.length >= allUploadedSlides.length"
          :is-no-more="uploadedSlides.length >= allUploadedSlides.length"
          :load-more-slides="handleLoadMoreUploadedSlides"
          :handle-favorite="handleFavorite"
          :handle-download-from-list="handleDownloadFromList"
          :handle-detail-click="handleSlideDetailClick"
          :saved-query-payload="savedQueryPayload"
          :current-audience="
            audience ||
            currentUser.user.fingerprint || { fingerprint: 'director' }
          "
          :log-download="logDownload"
          :slides="uploadedSlides"
          :items-per-row="3"
          :list-downloads="getDownloadedSlides"
          :trending-slides="trendingSlides"
          :list-image-replaced="getImageReplacedSlides"
          :listIconReplaced="getIconReplacedSlides"
          :origin="hybridSearchOrigin"
          :slides-meta-data="uploadedSlidesMetaData"
          :currentThemeCode="currentUser.theme.code || 'default'"
          :removeSlideOnDelete="removeSlideOnDelete"
          :downloadingSlides="downloadingSlides"
          :addingToFavSlides="addingToFavSlides"
        />
      </div>
      <!-- End of Slide Library -->
      <!-- Best Practice Library -->
      <div
        v-if="
          chipsSelected['best practice library'] &&
          !(searchKnowSlides || searchProgress)
        "
        :class="
          leftSideBarStatus
            ? 'slides-wrapper know-result-wrapper'
            : 'slides-wrapper know-result-wrapper prezentation-wrapper-small'
        "
      >
        <div class="slides-wrapper-title">
          {{ $t('rightSideBar.bestPracticesText') }}
        </div>
        <template v-if="knowSlides.length > 0">
          <!-- <div class="slides-wrapper-subtitle">
            {{ $t('search.bestPracticeSlides') }}
          </div> -->
          <div class="top-wrapper">
            <div class="slides-wrapper-subtitle">
              {{ $t('search.bestPracticeSlides') }}
            </div>
            <GoToFeatureLanding
              :pageType="'bpe-slides'"
              :handleLandingPageClick="handleLandingPageClick"
            ></GoToFeatureLanding>
          </div>
          <v-row v-if="knowSlides.length > 0">
            <v-col
              v-for="(slide, ind) in knowSlides"
              :key="`${slide.id}_${ind}`"
              cols="4"
            >
              <KnowSlideCard
                :slide="slide"
                :metaData="knowMetadata[slide.unique_id] || {}"
                :origin="hybridSearchOrigin"
                :handle-detail-click="handleSlideDetailClick"
              />
            </v-col>
          </v-row>
          <div class="see-more-btn">
            <v-btn
              id="loadmore-btn"
              class="loadmore-btn"
              style="text-transform: none"
              @click="handleLoadMoreKnowSlides"
              color="#21a7e0"
              rounded
              outlined
              height="32"
              :disabled="knowSlides.length >= knowAllSlides.length"
            >
              {{
                knowSlides.length >= knowAllSlides.length
                  ? $t('prezentationsList.noMoreResult')
                  : $t('prezentationsList.seeMoreResult')
              }}
            </v-btn>
            <p class="mt-2" v-if="knowSlides.length >= knowAllSlides.length">
              {{ $t('prezentationsList.limitedResultsSlide') }}
              <a class="text-link" @click="goToBPELibrary">
                {{ $t('search.bestPracticeSlides') }}
              </a>
            </p>
          </div>
        </template>
        <template v-if="bpeDecks.length > 0">
          <div class="top-wrapper">
            <div class="slides-wrapper-subtitle">
              {{ $t('search.bestPracticesDecks') }}
            </div>
            <GoToFeatureLanding
              :pageType="'bpe-decks'"
              :handleLandingPageClick="handleLandingPageClick"
            ></GoToFeatureLanding>
          </div>
          <KnowDecks
            :allDecksFromHybridSearch="allBpeDecks"
            :decksFromHybridSerach="bpeDecks"
            :origin="'hybrid_search'"
            :handleLoadMore="handleLoadMoreBPEDecks"
          />
        </template>
      </div>
      <!-- End of Best Practice Library -->
      <!-- Templates -->
      <div
        v-if="
          chipsSelected.templates &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        :class="
          leftSideBarStatus
            ? 'template-wrapper border-wrapper'
            : 'template-wrapper border-wrapper people-wrapper-small'
        "
      >
        <div class="top-wrapper">
          <div class="template-wrapper-title">
            {{ $t('profile.templates') }}
          </div>
          <GoToFeatureLanding
            :pageType="'templates'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <v-row>
          <v-col
            class="available-template-list col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
            :style="[
              $vuetify.breakpoint.xs ||
              $vuetify.breakpoint.sm ||
              $vuetify.breakpoint.md
                ? { 'min-width': '300px' }
                : {},
            ]"
            cols="12"
            sm="4"
            v-for="(item, ind) in templatesFiltered"
            :key="`${item.id}_${ind}`"
          >
            <div class="available-template">
              <div class="available-template-wrapper">
                <v-badge
                  color="#075689"
                  content="New"
                  overlap
                  tile
                  offset-x="66"
                  offset-y="32"
                  :value="displayBadgeInTheme(item)"
                >
                  <div class="available-template-img">
                    <img
                      :src="item.thumbnailURL"
                      alt
                      :style="{ cursor: 'pointer' }"
                      @click="goToTemplateTab(item)"
                    />
                  </div>
                </v-badge>
                <v-tooltip
                  bottom
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-title
                      v-bind="attrs"
                      v-on="on"
                      v-text="item.name"
                      class="card__title text-truncate available-template-title"
                    />
                  </template>
                  <span class="tooltip">{{ item.name }} </span>
                </v-tooltip>
              </div>
              <v-btn
                :text="
                  currentTheme &&
                  currentTheme.code &&
                  currentTheme.code === item.code
                "
                :rounded="
                  currentTheme &&
                  currentTheme.code &&
                  currentTheme.code !== item.code
                "
                :color="
                  currentTheme &&
                  currentTheme.code &&
                  currentTheme.code !== item.code
                    ? '#21a7e0'
                    : ''
                "
                :class="`select-template ${
                  currentTheme &&
                  currentTheme.code &&
                  currentTheme.code === item.code
                    ? 'active'
                    : ''
                }`"
                height="36"
                min-width="64"
                @click="handleClickTheme(item)"
              >
                {{
                  currentTheme &&
                  currentTheme.code &&
                  currentTheme.code === item.code
                    ? $t('search.currentSelection')
                    : $t('common.select')
                }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <div
          v-if="templatesFiltered.length < allTemplatesFiltered.length"
          class="see-more-btn"
        >
          <v-btn
            id="loadmore-btn"
            class="loadmore-btn"
            style="text-transform: none"
            @click="handleLoadMoreTemplates"
            color="#21a7e0"
            rounded
            outlined
            height="32"
            :disabled="templatesFiltered.length >= allTemplatesFiltered.length"
          >
            {{
              templatesFiltered.length >= allTemplatesFiltered.length
                ? $t('prezentationsList.noMoreResult')
                : $t('prezentationsList.seeMoreResult')
            }}
          </v-btn>
        </div>
      </div>
      <!-- End of Templates -->
      <!-- Courses -->
      <div
        v-if="
          chipsSelected.learn &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        :class="
          leftSideBarStatus
            ? 'learn-wrapper'
            : 'learn-wrapper people-wrapper-small'
        "
      >
        <!-- <div class="learn-wrapper-title">
          {{ $t('hybridSearchPage.courses_label') }}
        </div> -->
        <div class="top-wrapper">
          <div class="learn-wrapper-title">
            {{ $t('hybridSearchPage.courses_label') }}
          </div>
          <GoToFeatureLanding
            :pageType="'courses'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <div class="learn-wrapper-items row">
          <div v-for="(item, index) in learn" :key="index" style="width: 50%">
            <!-- Display only for video -->
            <div
              v-if="item.stype === 'video'"
              class="learn-wrapper-item"
              @click="handleLearnClick(item)"
            >
              <div class="learn-wrapper-item-image-wrapper">
                <img
                  src="/assets/img/learn/learn-hybrid-search-new.svg"
                  alt
                  height="150px"
                  @click="handleLearnClick(item)"
                />
              </div>
              <div class="learn-wrapper-item-info-wrapper">
                <div class="learn-wrapper-item-info-title">
                  <b
                    >{{ $t('learn.course.video') }}: {{ item.decodedTitle }} ({{
                      item.etags.lm_duration
                    }}
                    {{ $t('fingerprint.searchPage.min') }})</b
                  >
                </div>
                <div class="learn-wrapper-item-info-desc">
                  {{ $t('learn.learningPageLocked.module') }}:
                  {{ item.etags.lm_module }}
                </div>
                <div class="learn-wrapper-item-info-desc">
                  {{ $t('search.course') }}: {{ item.etags.lm_course }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="learn.length < allLearn.length" class="learn-action">
          <v-btn
            rounded
            outlined
            color="#21a7e0"
            height="32"
            @click="handleLoadMoreLearn"
          >
            {{ $t('prezentationsList.seeMoreResult') }}
          </v-btn>
        </div>
      </div>
      <!-- End of Courses -->
      <!-- Help -->
      <div
        v-if="
          chipsSelected.help &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        :class="
          leftSideBarStatus
            ? 'help-wrapper border-wrapper'
            : 'help-wrapper border-wrapper people-wrapper-small'
        "
      >
        <div class="top-wrapper">
          <div class="help-wrapper-title">{{ $t('help.help') }}</div>
          <GoToFeatureLanding
            :pageType="'help'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <div class="help-wrapper-content">
          <v-row>
            <v-col
              cols="6"
              sm="12"
              md="6"
              :key="i"
              v-for="(helpItem, i) in eligibleHelpItems"
            >
              <div
                class="help-wrapper-item"
                @click="() => handleClickHelp(helpItem)"
              >
                <div class="help-wrapper-item-icon">
                  <div class="inner">
                    <img
                      :src="typeIcons[helpItem.type] || typeIcons.Quicktips"
                      alt=""
                    />
                  </div>
                </div>
                <div class="help-wrapper-item-content">
                  <div class="inner">
                    <p class="help-wrapper-item-name">{{ helpItem.name }}</p>
                    <p class="help-wrapper-item-type">{{ helpItem.type }}</p>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="help.length < allHelp.length" class="help-action">
          <v-btn
            rounded
            outlined
            color="#21a7e0"
            height="32"
            @click="handleLoadMoreHelp"
          >
            {{ $t('fingerprint.searchPage.seeMoreResults') }}
          </v-btn>
        </div>
      </div>
      <!-- End of Help -->
      <!-- Prezentation Library -->
      <div
        v-show="
          chipsSelected.prezentations &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        :class="
          leftSideBarStatus
            ? 'prezentations-wrapper prezentations-result-wrapper'
            : 'prezentations-wrapper prezentations-result-wrapper prezentation-wrapper-small'
        "
        :key="'prezentation'"
      >
        <!-- <div class="prezentations-wrapper-title">
          {{ $t('hybridSearchPage.prez_exchange_label') }}
        </div> -->
        <div class="top-wrapper">
          <div class="prezentations-wrapper-title">
            {{ $t('hybridSearchPage.prez_exchange_label') }}
          </div>
          <GoToFeatureLanding
            :pageType="'prezentation'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <PrezentationCardList
          :loaded-prezentations="prezentations"
          :is-editable="true"
          :is-search-page="true"
          :origin="hybridSearchOrigin"
          :snapshot="snapshot.PrezentationCardList"
          @select="onPrezentationClick($event)"
        >
          <template v-slot:empty-state>
            <EmptyState
              img-url="/assets/img/view-prez-no-prezentations-yet.svg"
              :description="'search.noPresentations'"
              :is-btn-visible="true"
              :handleCTA="goToNewPrezentation"
            />
          </template>
        </PrezentationCardList>
      </div>
      <!-- End of Prezentation Library -->
      <!-- Overnight Requests -->
      <div
        v-if="
          chipsSelected['overnight requests'] &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        class="slides-wrapper know-result-wrapper"
        :class="
          leftSideBarStatus
            ? 'slides-wrapper know-result-wrapper'
            : 'slides-wrapper know-result-wrapper prezentation-wrapper-small'
        "
      >
        <!-- <div class="slides-wrapper-title">
          {{ $t('overnightPresentations.overnightRequests') }}
        </div> -->
        <div class="top-wrapper">
          <div class="slides-wrapper-title">
            {{ $t('overnightPresentations.overnightRequests') }}
          </div>
          <GoToFeatureLanding
            :pageType="'OP'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <v-row>
          <v-col
            v-for="(oitm, i) in opTickets"
            :key="`${oitm.ticketId}__${i}`"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
            :style="[
              $vuetify.breakpoint.xs ||
              $vuetify.breakpoint.sm ||
              $vuetify.breakpoint.md
                ? { 'min-width': '300px' }
                : {},
            ]"
          >
            <v-item v-slot="{ active, toggle }">
              <OvernightPrezentationCard
                @click.native="selectOPTicket(oitm)"
                @download="download"
                @onRatingUpdate="onRatingUpdate"
                :active="active"
                :toggle-fn="toggle"
                class="op-card"
                :prezentation="oitm"
              />
            </v-item>
          </v-col>
        </v-row>
        <div class="see-more-btn">
          <v-btn
            id="loadmore-btn"
            class="loadmore-btn"
            style="text-transform: none"
            @click="handleLoadMoreOPTickets"
            color="#21a7e0"
            rounded
            outlined
            height="32"
            :disabled="allOPResultsLoaded()"
          >
            {{
              allOPResultsLoaded()
                ? $t('prezentationsList.noMoreResult')
                : $t('prezentationsList.seeMoreResult')
            }}
          </v-btn>
        </div>
      </div>
      <!-- End of Overnight Requests -->
      <!-- Storybuilder -->
      <div
        v-if="
          chipsSelected.build &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        class="storylinesWrapper border-wrapper"
        :class="
          leftSideBarStatus
            ? 'storylinesWrapper border-wrapper'
            : 'storylinesWrapper border-wrapper people-wrapper-small'
        "
      >
        <div class="top-wrapper">
          <div class="storylinesTitle">
            {{ $t('hybridSearchPage.story_builder_label') }}
          </div>
          <GoToFeatureLanding
            :pageType="'storybuilder'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <div
          class="storylinesList"
          :style="{ 'grid-template-columns': frames }"
        >
          <div
            v-for="(stls, i) in buildFiltered"
            :key="i"
            class="storylinesListItem"
          >
            <div
              class="storylineIconContainer"
              @click="() => handleBuildClick(stls)"
            >
              <img src="/assets/img/search/add-icon.svg" alt />
            </div>
            <div class="storyLineInfo">
              <div class="name">
                {{ stls.type }}
                <img
                  src="/assets/img/prezent_avatar.svg"
                  alt
                  width="22px"
                  v-if="stls.isPrezentGenerated"
                />
              </div>
              <!--
              <div class="description">{{ stls.description }}</div>
              !-->
              <div class="author">
                {{ $t('search.storyline', { Type: stls.type }) }}
              </div>
              <div class="author">
                {{ $t('build.step3.source') }}:
                <span v-if="stls.isPrezentGenerated">
                  {{ 'Prezent' }}
                </span>
                <span v-else>
                  {{ currentUser.company.displayName }}
                </span>
                <span v-if="!stls.isPrezentGenerated">
                  | {{ $t('build.step3.author') }}:
                  <span>
                    {{
                      `${isOwner(stls) ? $t('build.step1.me') : stls.fullName}`
                    }}
                  </span>
                </span>
              </div>
              <div class="createdDate" v-if="!stls.isPrezentGenerated">
                {{
                  $t('search.createdOn', {
                    getDate: getDateWithMoment(stls.createdAt, 'DD/MM/YYYY'),
                  })
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="filteredBuild.length <= allFilteredStorylines.length"
          class="loadMoreStroylinesBtn"
        >
          <v-btn
            id="loadmore-btn"
            style="text-transform: none"
            @click="handleLoadMoreBuild"
            color="#21a7e0"
            rounded
            outlined
            height="32"
            :disabled="filteredBuild.length >= allFilteredStorylines.length"
          >
            {{
              filteredBuild.length >= allFilteredStorylines.length
                ? $t('prezentationsList.noMoreResult')
                : $t('prezentationsList.seeMoreResult')
            }}
          </v-btn>
        </div>
      </div>
      <!-- End of Storybuilder -->
      <!-- Storylines -->
      <div
        v-if="
          chipsSelected.storylines &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
        :class="
          leftSideBarStatus
            ? 'storylinesWrapper border-wrapper'
            : 'storylinesWrapper border-wrapper people-wrapper-small'
        "
      >
        <div class="top-wrapper">
          <div class="storylinesTitle">{{ $t('profile.storylines') }}</div>
          <GoToFeatureLanding
            :pageType="'storylines'"
            :handleLandingPageClick="handleLandingPageClick"
          ></GoToFeatureLanding>
        </div>
        <div
          class="storylinesList"
          :style="{ 'grid-template-columns': frames }"
        >
          <div
            v-for="(stls, i) in storylinesFiltred"
            :key="i"
            class="storylinesListItem"
          >
            <div
              class="storylineIconContainer"
              @click="() => handleStorylineClick(stls)"
            >
              <img
                src="/assets/icons/search/Storylines-hybrid-search-icon.svg"
                alt
              />
            </div>
            <div class="storyLineInfo">
              <div class="name">
                {{ stls.type }}
                <img
                  src="/assets/img/prezent_avatar.svg"
                  alt
                  width="22px"
                  v-if="stls.isPrezentGenerated"
                />
              </div>
              <div class="author">
                {{ $t('build.step3.source') }}:
                <span v-if="stls.isPrezentGenerated">
                  {{ 'Prezent' }}
                </span>
                <span v-else>
                  {{ currentUser.company.displayName }}
                </span>
                <span v-if="!stls.isPrezentGenerated">
                  | {{ $t('build.step3.author') }}:
                  <span>
                    {{
                      `${isOwner(stls) ? $t('build.step1.me') : stls.fullName}`
                    }}
                  </span>
                </span>
              </div>
              <div class="createdDate" v-if="!stls.isPrezentGenerated">
                {{
                  $t('search.createdOn', {
                    getDate: getDateWithMoment(stls.createdAt, 'DD/MM/YYYY'),
                  })
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="filteredStorylines.length <= allFilteredStorylines.length"
          class="loadMoreStroylinesBtn"
        >
          <v-btn
            id="loadmore-btn"
            style="text-transform: none"
            @click="handleLoadMoreStorylines"
            color="#21a7e0"
            rounded
            outlined
            height="32"
            :disabled="
              filteredStorylines.length >= allFilteredStorylines.length
            "
          >
            {{
              filteredStorylines.length >= allFilteredStorylines.length
                ? $t('prezentationsList.noMoreResult')
                : $t('prezentationsList.seeMoreResult')
            }}
          </v-btn>
        </div>
      </div>
      <!-- End of Storylines -->

      <!-- No Results Page -->
      <div>
        <div
          v-if="
            selectedChip === 'people' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/no-results-found-people.svg"
            :is-btn-visible="true"
            :buttonName="'fingerprint.searchPage.addNewAudience'"
            :description="getDescription"
            :handleCTA="addNewAudience"
          />
        </div>
        <div
          v-if="
            selectedChip === 'learn' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/no-results-found-generic.svg"
            :is-btn-visible="true"
            :buttonName="'searchLandingCTAtext.courses'"
            :handleCTA="goToCourse"
          />
        </div>
        <div
          v-if="
            selectedChip === 'prezentations' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/view-prez-no-prezentations-yet.svg"
            :is-btn-visible="true"
            :buttonName="'profile.storylinesTabContent.buildNewPrezentation'"
            :description="getDescription"
            :handleCTA="onCreatePrezentations"
          />
        </div>
        <div
          v-if="
            selectedChip === 'overnight requests' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <EmptyState
            img-url="/assets/img/view-prez-no-prezentations-yet.svg"
            :description="'overnightPresentations.noRequestsFound'"
            :is-btn-visible="true"
            :is-btn-disabled="disableNewReq()"
            :handleCTA="submitNewRequest"
            :button-name="'overnightPresentations.submitNewRequest'"
          />
        </div>
        <div
          v-if="
            selectedChip === 'best practice library' &&
            !chipsSelected[selectedChip] &&
            !searchKnowSlides &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/no-results-found-know.svg"
            :is-btn-visible="false"
            :description="getDescription"
          />
        </div>
        <div
          v-if="
            selectedChip === 'storylines' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/no-results-found-generic.svg"
            :is-btn-visible="true"
            :buttonName="'profile.storylinesTabContent.buildNewPrezentation'"
            :description="getDescription"
            :handleCTA="onCreatePrezentations"
          />
        </div>
        <div
          v-if="
            selectedChip === 'build' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/no-results-found-generic.svg"
            :is-btn-visible="true"
            :buttonName="'profile.storylinesTabContent.buildNewPrezentation'"
            :description="getDescription"
            :handleCTA="onCreatePrezentations"
          />
        </div>
        <div
          v-if="
            selectedChip === 'templates' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/no-results-found-templates.svg"
            :is-btn-visible="true"
            :buttonName="'search.addNewTemplate'"
            :description="getDescription"
            :handleCTA="handleAddMore"
          />
        </div>
        <div
          v-if="
            selectedChip === 'help' &&
            !chipsSelected[selectedChip] &&
            !(searchProgress || loading || !startLoadingSlidesFlag)
          "
          class="not-found-container"
        >
          <NoResultPage
            img-url="/assets/img/help/no-results-found-send-support-request.svg"
            :is-btn-visible="true"
            :buttonName="'help.supportRequest'"
            :description="getDescription"
            :handleCTA="handleOpenSendRequest"
          />
        </div>
      </div>
      <div
        class="not-found-container"
        v-if="
          ['all', 'slides'].includes(selectedChip) &&
          !chipsSelected[selectedChip] &&
          !searchSlides &&
          !searchKnowSlides &&
          !(searchProgress || loading || !startLoadingSlidesFlag)
        "
      >
        <div>
          <img src="/assets/img/no-results-found-slides.svg" alt />
        </div>

        <div class="not-found-descriptions">
          <div class="font-weight-bold mb-1 mt-6">
            {{ $t('tagSlides.noResultFound') }}
          </div>
          <div>{{ $t('search.dontLoseHeart') }}</div>
          <template v-if="savedQueryPayload && savedQueryPayload.query">
            <div class="mb-1 mt-6">
              {{ $t('slides.lookingForSlideText1') }}
              <span class="font-weight-bold"
                >'{{ savedQueryPayload.query }}'</span
              >
              <template v-if="!getGenerateAccessLevel">{{
                $t('slides.lookingForSlideText2')
              }}</template
              >?
            </div>
            <template v-if="getGenerateAccessLevel">
              <div class="mb-4">
                {{ $t('slides.generateSlideText') }}
              </div>
              <v-btn
                class="primary-button"
                rounded
                color="#21a7e0"
                height="48"
                min-width="134"
                @click="generate"
              >
                {{ $t('slides.generate') }}
              </v-btn>
            </template>
            <template v-else>
              <div class="mb-4">
                {{ $t('slides.buildSlideText') }}
              </div>
              <v-btn
                id="walkthrough-try-now-btn-id"
                class="primary-button"
                rounded
                color="#21a7e0"
                height="48"
                min-width="134"
                @click="onTryNow"
              >
                {{ $t('slides.tryNow') }}
              </v-btn>
            </template>
            <template v-if="selectedChip === 'all'">
              <div class="my-4">
                {{ $t('fingerprint.searchPage.description') }}
              </div>
              <v-btn
                id="add-audience-now-btn-id"
                class="primary-button"
                rounded
                color="#21a7e0"
                height="48"
                min-width="134"
                @click="addNewAudience"
              >
                {{ $t('fingerprint.searchPage.addNewAudience') }}
              </v-btn>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div id="go-top" class="go-top" v-show="false" v-scroll="onScroll"></div>
  </div>
</template>

<script>
import API, { graphqlOperation } from '@aws-amplify/api-graphql';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import he from 'he';
import {
  getSlideDetail,
  createFavorite,
  deleteFavorite,
  createDownload,
  getKnowSlideMetaData,
  logSearchQuery,
  downloadFinalOPPPT,
  logOPDownload,
  getUserSearchTerms,
  updateSearchTerms,
  addSearchTerms,
  getCollection,
} from '@/utils/api-helper';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import { performSearch, userByCompany } from '@/graphql/queries';
import EventBus from '../../common/event-bus';
// import { getFileURL } from '@/utils/calyrex';
// import { getFileURLDownload } from '@/utils/calyrex-test';
import {
  downloadFileWithCustomName,
  getFullName,
  // downloadFileWithAPI,
} from '@/utils/common';
import { getTrendingSlides } from '@/utils/trending-slides';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import utils from '../../utils';
import PrezentationCardList from '@/components/Main/Prezentations/PrezentationCardList';
import EmptyState from '../../common/EmptyState.vue';
import NoResultPage from '../../common/NoResultPage.vue';
import usersApi from '../../../API/users-api';
import { PendoAnalyticsHandler } from '../../common/Analytics/PendoAnalyticsHandler';
import HelpApi from '../../../API/help-api';
import InlineSlides from '@/components/common/InlineSlides';
import GoToFeatureLanding from '@/components/common/GoToFeatureLanding';
import AudienceItem from '@/components/common/AudienceItem';
import {
  replacePrivateInParenthesis,
  getIsGroup,
  formatUserForPrivateAudienceItem,
} from '../../../utils/common';
import { miscConfig } from '../../../runtimeConfig';
import KnowSlideCard from '../Know/KnowSlideCard.vue';
import KnowDecks from '../Know/KnowDecks.vue';
import OvernightPrezentationCard from '../OvernightPrezentations/OvernightPrezentationCard.vue';
import SlideThemeChangeDetail from '../Profile/SlideThemeChangeDetail.vue';
import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import AddingMoreTemplateDetail from '../Profile/AddingMoreTemplateDetail.vue';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import Loading from '../../common/Loading.vue';
import {
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_ISDOWNLOAD,
  isSlideFavorite,
  getSlideId,
  TD_ISFAVORITED,
  TD_PEOPLERESULTS,
  TD_KNOWRESULTS,
  TD_LEARNRESULTS,
  TD_SLIDESRESULTS,
  TD_HELPRESULTS,
  TD_PREZENTATIONRESULTS,
  TD_QUERY,
  TD_DOWNLOADTYPE_GS,
  TD_SLIDEID,
  TD_HYBRIDCHIP,
  NA,
  TD_NORESULTSFOUND,
  TD_HYBRID_SEARCH,
  TD_HYBRID_RESULTS_SECTION_CLICK,
  TD_LEARNCOURSENAME,
  TD_TEMPLATE,
  TD_HYBRID_RESULTS_SECTION_TEMPLATES,
  TD_HYBRID_RESULTS_SECTION_SLIDES,
  TD_HYBRID_RESULTS_SECTION_LEARN,
  TD_HYBRID_RESULTS_SECTION_PEOPLE,
  TD_HYBRID_RESULTS_SECTION_HELP,
  TD_HYBRID_RESULTS_SECTION_PREZ,
  TD_HYBRID_RESULTS_SECTION_ALL,
  TD_HYBRID_RESULTS_SECTION_STORYLINES,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_REST_OF_THE_SLIDES_IN_UI,
  TD_PLACEMENT,
  TD_THUMBNAIL_VIEW,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import {
  getTypeSenseAPIKey,
  TypeSenseServer,
} from '../../../pages/components/TypeSenseInit';
import {
  getGeneratedUploadedSlides,
  getOPTicket,
  getPrezentationByID,
  getPrezentationSearch,
} from '../../../utils/api-helper';
import { GraphQLService } from '../../../services/GraphQLService';
import { getAccessLevelFormatedStrings } from '../../../utils/utility';
// import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';

const typesLocale = {
  S_QUICKTIP: 'Quick-tips',
  S_TUTORIAL: 'Tutorials',
  S_FAQ: 'FAQs',
  S_WALKTHROUGH: 'Walkthroughs',
};
const mapType = (t) => typesLocale[t] || 'Help';

export default {
  name: 'Search',
  components: {
    PrezentationCardList,
    InlineSlides,
    EmptyState,
    KnowSlideCard,
    OvernightPrezentationCard,
    NoResultPage,
    AudienceItem,
    Loading,
    KnowDecks,
    GoToFeatureLanding,
  },
  data() {
    return {
      frames: '1fr 1fr 1fr',
      filteredStorylines: [],
      filteredBuild: [],
      allFilteredStorylines: [],
      leftNavExpanded: false,
      tsKey: '',
      opTickets: [],
      opLimit: 4,
      opPage: 1,
      bpeDecksPage: 1,
      OPSearchData: {},
      selectedChip: 'all',
      startIndex: 0,
      startUploadedIndex: 0,
      slideMetaData: {},
      trendingSlides: [],
      onBoardingTutorial: [],
      notValidId: [],
      typeIcons: {
        Tutorials: `/assets/icons/search/tutorial2.svg`,
        Walkthroughs: `/assets/icons/search/walkthrough2.svg`,
        Quicktips: `/assets/icons/search/quicktips2.svg`,
        FAQs: `/assets/icons/search/faqs2.svg`,
      },
      disableDownload: false,
      people: [],
      allPeople: [],
      pageSize: 9,
      searchSlides: true,
      searchKnowSlides: true,
      slidesPageSize: 6,
      brandSlidePageSize: 6,
      uploadedSlides: [],
      allUploadedSlides: [],
      loading: false,
      audiencePageSize: 16,
      savedQueryPayload: null,
      cacheKey: 0,
      componentKey: 0,
      audienceList: [],
      prezentations: [],
      allPrezentations: [],
      searchProgress: true,
      learn: [],
      help: [],
      allHelp: [],
      allLearn: [],
      keywords: [
        {
          name: 'All',
          title: 'overnightPresentations.all',
          category: 'Agenda',
        },
        {
          name: 'People',
          title: 'fingerprint.searchPage.people',
          category: 'People',
        },
        {
          name: 'Slides',
          title: 'hybridSearchPage.slide_lib_chip',
          category: 'Slides',
        },
        {
          name: 'Templates',
          title: 'profile.templates',
          category: 'templates',
        },
        {
          name: 'Learn',
          title: 'hybridSearchPage.courses_chip',
          category: 'Learn',
        },
        { name: 'Help', title: 'help.help', category: 'Help' },
        {
          name: 'Prezentations',
          title: 'hybridSearchPage.prez_exchange_chip',
          category: 'Prezentations',
        },
        {
          name: 'Build',
          title: 'hybridSearchPage.story_builder_chip',
          category: 'Build',
        },
        {
          name: 'Storylines',
          title: 'profile.storylines',
          category: 'storylines',
        },
      ],
      slides: [],
      allSlides: [],
      listDownloads: [],
      avatars: {
        director: 'lion.png',
        navigator: 'Wolf.png',
        scholar: 'Owl.png',
        performer: 'Peacock.png',
        architect: 'Horse.svg',
        surgeon: 'Eagle.png',
        scientist: 'Dolphin.png',
        producer: 'Bear.png',
      },
      eligibleWalkthroughs: [],
      knowSlides: [],
      knowAllSlides: [],
      uploadedSlidesMetaData: {},
      knowMetadata: {},
      allTemplatesFiltered: [],
      templatesFiltered: [],
      searchResultFoundCount: 0,
      searchLogged: false,
      searchTermToLog: '',
      searchTermTranslatedToLog: null,
      searchResultCompletedInfo: {
        people: false,
        slides: false,
        learn: false,
        prezentation: false,
        'best practice library': false,
        template: false,
        help: false,
        op: false,
      },
      hybridSearchOrigin: TD_HYBRID_SEARCH,
      searchTerms: [],
      featureEnablementConfig: {
        Know: 'isKnowEnabled',
        'Overnight Prezentations': 'hasOPAccess',
        Uploader: 'getUploadAccessLevel',
        'Auto Generator': 'getGenerateAccessLevel',
        'Template Converter': 'isComplyEnabledWithAccess',
        Synthesis: 'isSynthesisFullAccess',
        Redesign: 'isRedesignFullAccess',
        Accelerators: 'isAcceleratorsEnabled',
        'ASTRID™ AI': 'checkAstridAccess',
        ASTRID: 'checkAstridAccess',
      },
      downloadingSlides: [],
      addingToFavSlides: [],
      offsetTop: 0,
      allBpeDecks: [],
      bpeDecks: [],
    };
  },
  computed: {
    ...mapState('search', ['snapshot']),
    ...mapState('users', [
      'currentUser',
      'assetVotes',
      'audience',
      'currentTheme',
      'currentUserFavorites',
      'leftSideBarStatus',
      'themes',
      'startLoadingSlidesFlag',
      'typesenseRealtimeAudiences',
      'typesenseSelfAudience',
      'typesenseAudiencesLoaded',
    ]),
    ...mapGetters('prezentations', [
      'getAllPrezentations',
      'getFilteredAndSearchedPrezentations',
    ]),
    ...mapState('users', ['assetFolder']),
    ...mapState('prezentations', ['prezentationBackClicked']),
    // ...mapState('help', ['payloads']),
    ...mapGetters('users', [
      'isKnowEnabled',
      'getOPAccessLevel',
      'getOPLimitedBanner',
      'getUploadAccessLevel',
      'getGenerateAccessLevel',
      'isSynthesisFullAccess',
      'isRedesignFullAccess',
      'isAcceleratorsEnabled',
    ]),
    ...mapGetters('slidesStore', [
      'getFavoriteSlides',
      'getImageReplacedSlides',
      'getIconReplacedSlides',
      'getDownloadedSlides',
      'getSlidesSnapshot',
    ]),
    ...mapState('slidesStore', ['slideBackClicked']),
    isComplyEnabledWithAccess() {
      const complyFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'comply',
      );
      return (
        (complyFeature &&
          complyFeature.enabled &&
          complyFeature.access_level &&
          complyFeature.access_level === 'full_access') ||
        (complyFeature &&
          complyFeature.access_level &&
          (complyFeature.access_level === 'limited_access' ||
            complyFeature.access_level === 'restricted_access'))
      );
    },
    checkAstridAccess() {
      const featuresToCheck = [
        'slide-generate',
        'user-prezentation-upload',
        'synthesis',
        'comply',
        'redesign',
      ];
      const features = this.currentUser?.features;
      return features.some(
        (feature) =>
          featuresToCheck.includes(feature.feature_name) &&
          feature.enabled &&
          (feature.access_level === 'full_access' ||
            feature.access_level === 'restricted_access'),
      );
    },
    hasOPAccess() {
      return this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access';
    },
    eligibleHelpItems() {
      return this.help.filter((help) => this.isEligibleWalkthrough(help));
    },
    buildFiltered() {
      return this.filteredBuild.filter(
        (ele) => !(ele.type === 'Custom' && ele.isPrezentGenerated === true),
      );
    },
    storylinesFiltred() {
      return this.filteredStorylines.filter(
        (ele) => !(ele.type === 'Custom' && ele.isPrezentGenerated === true),
      );
    },
    searchQuery() {
      return this.savedQueryPayload?.query;
    },
    getDescription() {
      if (this.selectedChip === 'people')
        return this.$t('fingerprint.searchPage.description');
      if (this.selectedChip === 'learn') return this.$t('search.dontLoseHeart');
      if (this.selectedChip === 'best practice library')
        return this.$t('search.dontLoseHeart');
      if (this.selectedChip === 'help')
        return this.$t('search.sendSupportRequest');
      if (this.selectedChip === 'storylines')
        return this.$t('search.noStorylinesPresent');
      if (this.selectedChip === 'build')
        return this.$t('search.noStorylinesPresent');
      return '';
    },
    chipsSelected() {
      const matchChip = ['all'];
      return {
        all:
          this.people.length ||
          this.slides.length ||
          this.learn.length ||
          this.help.length ||
          this.prezentations.length ||
          (this.getOPAccessLevel &&
            this.getOPAccessLevel !== 'no_access' &&
            this.opTickets.length) ||
          (this.isKnowEnabled && this.knowSlides.length) ||
          this.templatesFiltered.length ||
          this.filteredStorylines.length ||
          this.filteredBuild.length,
        people:
          matchChip.concat('people').includes(this.selectedChip) &&
          this.people.length,
        slides:
          !this.searchSlides &&
          matchChip.concat('slides').includes(this.selectedChip) &&
          this.slides.length,
        'best practice library':
          !this.searchKnowSlides &&
          !this.searchProgress &&
          this.isKnowEnabled &&
          matchChip
            .concat('best practice library')
            .includes(this.selectedChip) &&
          (this.knowSlides.length || this.bpeDecks.length),
        'overnight requests':
          !this.searchProgress &&
          this.getOPAccessLevel &&
          this.getOPAccessLevel !== 'no_access' &&
          matchChip.concat('overnight requests').includes(this.selectedChip) &&
          this.opTickets.length,
        learn:
          matchChip.concat('learn').includes(this.selectedChip) &&
          this.learn.length,
        help:
          !this.loading &&
          matchChip.concat('help').includes(this.selectedChip) &&
          this.help.length,
        templates:
          !this.searchProgress &&
          matchChip.concat('templates').includes(this.selectedChip) &&
          this.templatesFiltered.length,
        storylines:
          !this.searchProgress &&
          matchChip.concat('storylines').includes(this.selectedChip) &&
          this.filteredStorylines.length,
        build:
          !this.searchProgress &&
          matchChip.concat('build').includes(this.selectedChip) &&
          this.filteredBuild.length,
        prezentations:
          !this.loading &&
          matchChip.concat('prezentations').includes(this.selectedChip) &&
          this.prezentations.length,
      };
    },
  },
  methods: {
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      'setNavFromAddToLibOrFavSnackbar',
      'setAddedToLibOrFavSnackbar',
    ]),
    ...mapActions('search', ['setIsSearchPage']),
    handleLandingPageClick(page) {
      if (page === 'people') {
        setTimeout(() => {
          EventBus.$emit('GOTO_FINGERPRINTS', this.searchTermToLog);
        }, 200);
        this.$router.push('/home/fingerprint');
      }
      if (page === 'slides') {
        this.setSlideLocalSearch(this.searchTermToLog);
        this.$router.push('/home/slides');
      }
      if (page === 'slides-collection') {
        this.setSlideLocalSearch(this.searchTermToLog);
        this.setSlideCollectionTool(true);
        this.$router.push('/home/slides');
      }
      if (page === 'bpe-decks') {
        this.setBPEDeckLocalSearchText(this.searchTermToLog);
        this.$router.push('/home/best-practice-library');
      }
      if (page === 'bpe-slides') {
        this.setBPESlideLocalSearchText(this.searchTermToLog);
        this.setBPEPreviousRoute('hybridSearch');
        this.setBPEviewType('Slides');
        this.$router.push('/home/best-practice-library');
      }
      if (page === 'courses') {
        setTimeout(() => {
          EventBus.$emit('GOTO_LEARN_LANDING', this.searchTermToLog);
        }, 200);
        this.$router.push('/home/learn');
      }
      if (page === 'prezentation') {
        setTimeout(() => {
          EventBus.$emit('GOTO_PREZENTATIONS', this.searchTermToLog);
        }, 200);
        this.$router.push('/home/prezentations');
      }
      if (page === 'OP') {
        setTimeout(() => {
          EventBus.$emit('GOTO_OP', this.searchTermToLog);
        }, 200);
        this.$router.push('/home/overnight-prezentations?tab=myRequest');
      }
      if (page === 'storybuilder') {
        this.$router.push('/home/build');
      }
      if (page === 'storylines') {
        setTimeout(() => {
          EventBus.$emit('GOTO_STORYLINES', this.searchTermToLog);
        }, 200);
        this.$router.push('/profile?tab=storylines');
      }
      if (page === 'templates') {
        setTimeout(() => {
          EventBus.$emit('GOTO_TEMPLATES', this.searchTermToLog);
        }, 200);
        this.$router.push('/profile?tab=templates');
      }
      if (page === 'help') {
        this.$root.$emit('openHelpMenuPopUp', 'search');
      }
    },
    async selectOPTicket(item) {
      const payload = {
        type: 'Overnight Prezentation',
        value: item.name,
        subvalue: item.name,
        attributes: `/home/my-overnight-prezentation/${item.ticketId}`,
        object: item,
      };
      this.updateRecentQueries(payload);
      this.$router.push({
        path: `/home/my-overnight-prezentation/${item.ticketId}`,
      });
    },
    ...mapActions('prezentations', [
      'setAllPrezentationsForLandingPage',
      'setFilterAndSearchedPrezentations',
    ]),
    ...mapActions('search', ['updateRecentQueries']),
    ...mapActions('slidesStore', [
      'setSlideBackClicked',
      'setSlideCollectionTool',
      'setSlideLocalSearch',
    ]),
    ...mapActions('bestPracticesExamples', [
      'setDecksOnLoad',
      'setDecksLoaded',
      'setBPEviewType',
      'setBPEDeckLocalSearchText',
      'setIsLoadMore',
      'setBPESlideLocalSearchText',
      'setBPEPreviousRoute',
    ]),

    ...mapActions('slidesCollection', ['setCollectionData']),

    async getOnboardingTutorialDetails() {
      return await HelpApi.methods
        .getHelpData(
          'tutorial',
          this.currentUser?.user?.language || 'en',
          'Overview',
        )
        .then(
          (resp) => {
            this.onBoardingTutorial = resp?.data;
            this.notValidId = resp?.not_valid_id;
          },
          (error) => {
            console.log(error.response);
          },
        );
    },
    getDownloadMetric(source, type) {
      if (source === 'uploaded' && type === 'Slide Shells') {
        return 'sanitizedSlidesDownloaded';
      }
      if (source === 'generated') {
        return 'generateSlidesDownloaded';
      }
      if (source === 'uploaded') {
        return 'uploadSlidesDownloaded';
      }
      if (source === 'synthesis') {
        return 'synthesizeSlidesDownloaded';
      }
      if (source === 'redesign') {
        return 'redesignSlidesDownloaded';
      }
      if (source === 'comply') {
        return 'complySlidesDownloaded';
      }
      return 'downloadUsed';
    },
    onScroll(event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;
    },
    isOwner(item) {
      return item?.userID === this.currentUser?.user?.id;
    },
    goToBPELibrary() {
      this.setBPEviewType('Slides');
      this.$router.push('/home/best-practice-library');
    },
    onPrezentationClick($event) {
      const { listComponentStateSnapshot } = $event;
      const snapshot = {
        Search: this.$data,
        PrezentationCardList: listComponentStateSnapshot,
      };
      this.setSearchStateSnapshot(snapshot);
      this.setPrezentationViewOrigin('search');
    },
    handleSlideDetailClick() {
      const snapshot = {
        Search: this.$data,
        Query: this.$route.query.s,
      };
      this.setSearchStateSnapshot(snapshot);
    },
    onRatingUpdate(payload) {
      const { ticketId, feedback, isLiked } = payload;
      this.opTickets = this.opTickets.map((item) => {
        if (ticketId === item.ticketId) {
          item.feedback = feedback;
          item.isLiked = isLiked;
        }
        return { ...item };
      });
    },
    formatOpRequests(ops) {
      const opsReqs = [];
      ops.forEach((op) => {
        if (op.storyline !== '') {
          opsReqs.push({
            isLiked: null,
            feedback: null,
            ...op,
            ticketId: op.id,
            createdAt: new Date(op.createdAtEpoch).toISOString(),
          });
        } else if (op.isCustomStoryline) {
          opsReqs.push({
            isLiked: null,
            feedback: null,
            ...op,
            storyline: 'Custom',
            ticketId: op.id,
            createdAt: new Date(op.createdAtEpoch).toISOString(),
          });
        } else {
          opsReqs.push({
            isLiked: null,
            feedback: null,
            ...op,
            storyline: 'NA',
            ticketId: op.id,
            createdAt: new Date(op.createdAtEpoch).toISOString(),
          });
        }
      });
      return opsReqs;
    },
    logOPTicketDownload(id) {
      logOPDownload(id)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    download(item) {
      downloadFinalOPPPT(item.ticketId)
        .then((resp) => {
          console.log(resp);
          downloadFileWithCustomName(
            resp.attachmentUrl,
            resp.fileName,
            (val) => {
              if (!val) {
                this.logOPTicketDownload(item.ticketId);
              }
            },
            false,
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async searchOPTickets({ query, page }) {
      const searchParameters = {
        q: query,
        query_by:
          'name,storyline,audienceFullName,audienceFirstName,audienceLastName,owner',
        page,
        per_page: this.opLimit,
      };
      const tsClient = await TypeSenseServer.getClient();
      return tsClient
        .collections('op_tickets')
        .documents()
        .search(searchParameters)
        .then((response) => {
          const { found, hits, page: currentSearchPage } = response;
          this.OPSearchData = {
            found,
            currentSearchPage,
          };
          if (hits?.length) {
            return hits.map((tkt) => ({
              ...tkt?.document,
            }));
          }
          return [];
        });
    },
    async loadOPTickets({ query, page }) {
      try {
        const searchParams = {
          query,
          page,
        };
        const searchResults = await this.searchOPTickets(searchParams);
        const items = await Promise.all(
          searchResults.map(async (ele) => {
            if (ele.status === 'Completed') {
              const prezentation = await getOPTicket(ele.id).catch((err) => {
                console.log(err);
              });
              return {
                ...ele,
                thumbnailImageURL:
                  prezentation?.data?.opSlideData?.slides[0]?.thumbnail,
              };
            }
            return ele;
          }),
        );
        if (items.length) return items;
        return [];
      } catch (e) {
        console.log('error while loading op tickets', e);
        return [];
      }
    },
    allOPResultsLoaded() {
      return this.opTickets?.length === this.OPSearchData?.found;
    },
    submitNewRequest() {
      this.$router.push({
        path: `/home/overnight-prezentations?tab=newRequest`,
      });
    },
    ...mapActions('search', ['setSearchStateSnapshot']),
    ...mapActions('users', [
      'setCurrentUser',
      'setCurrentTheme',
      'fetchCurrentUserFavorites',
      'toggleFavoriteBeforeSyncWithDB',
      'setSavedQueryPayload',
      'addSearchTerms',
    ]),
    ...mapActions('slidesStore', [
      'addToFavoriteSlides',
      'removeFromFavoriteSlides',
      'addToImageReplacedSlides',
      'addToIconReplacedSlides',
      'addToDownloadedSlides',
      'setSlidesLoadedInUI',
    ]),
    ...mapActions('prezentations', ['setPrezentationViewOrigin']),
    async handleStorylineClick(storyline) {
      trackSearchEvents.hybridSearchAssetClick(this.currentUser, {
        [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
        [TD_QUERY]: this.savedQueryPayload?.query,
        [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_STORYLINES,
      });
      const payload = {
        type: 'Storyline',
        value: storyline.type,
        subvalue: storyline.type,
        attributes: `/home/storyline/${storyline.storylineId.toLocaleLowerCase()}`,
        object: storyline,
      };
      this.updateRecentQueries(payload);
      this.$router.push(
        `/home/storyline/${storyline.storylineId.toLocaleLowerCase()}`,
      );
    },
    async handleBuildClick(storyline) {
      const payload = {
        type: 'Build',
        value: storyline.type,
        subvalue: storyline.type,
        attributes: `/home/build?outline_id=${storyline.storylineId.toLocaleLowerCase()}`,
        object: storyline,
      };
      this.updateRecentQueries(payload);
      this.$router.push(
        `/home/build?outline_id=${storyline.storylineId.toLocaleLowerCase()}`,
      );
    },
    getDateWithMoment(date, frmt) {
      return moment(date).format(frmt);
    },
    generate() {
      this.$router.push(
        `/home/generate?prompt=${this.savedQueryPayload.query}`,
      );
    },
    onTryNow() {
      this.$router.push(
        `/home/slides?category=${this.savedQueryPayload.category}&query=${this.savedQueryPayload.query}&imgReplaceWalkthrough=true`,
      );
      const hybridSection =
        this.selectedChip === 'all'
          ? TD_HYBRID_RESULTS_SECTION_ALL
          : TD_HYBRID_RESULTS_SECTION_SLIDES;
      trackSearchEvents.searchHybridNoResultsAction(
        this.currentUser,
        hybridSection,
        {
          [TD_HYBRID_RESULTS_SECTION_CLICK]: hybridSection,
          [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
          [TD_QUERY]: this.savedQueryPayload?.query,
        },
      );
    },
    isTrendingSlide(slide) {
      return this.trendingSlides.includes(slide.unique_id);
    },
    async fetchTrendingSlides() {
      try {
        const { data } = await getTrendingSlides({
          companyID: this.currentUser.companyID,
          themeID: this.currentUser?.theme?.code,
        });

        this.trendingSlides = data.map((item) => item.assetID);
        console.log('trending slides ->', this.trendingSlides);
      } catch (e) {
        this.trendingSlides = [];
      }
    },
    onCreatePrezentations() {
      this.$router.push('/home/build');
      trackSearchEvents.searchHybridNoResultsAction(
        this.currentUser,
        TD_HYBRID_RESULTS_SECTION_PREZ,
        {
          [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_PREZ,
          [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
          [TD_QUERY]: this.savedQueryPayload?.query,
        },
      );
    },
    goToCourse() {
      this.$router.push('/home/learn');
    },
    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          onHandleSubmit: this.onAddMoreTemplatesSubmit,
          templateRequestFrom: 'HybridSearch',
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    onAddMoreTemplatesSubmit() {
      trackSearchEvents.searchHybridNoResultsAction(
        this.currentUser,
        TD_HYBRID_RESULTS_SECTION_TEMPLATES,
        {
          [TD_HYBRID_RESULTS_SECTION_CLICK]:
            TD_HYBRID_RESULTS_SECTION_TEMPLATES,
          [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
          [TD_QUERY]: this.savedQueryPayload?.query,
        },
      );
    },
    handleOpenSendRequest() {
      trackSearchEvents.searchHybridNoResultsAction(
        this.currentUser,
        TD_HYBRID_RESULTS_SECTION_HELP,
        {
          [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_HELP,
          [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
          [TD_QUERY]: this.savedQueryPayload?.query,
        },
      );
      this.$root.$emit('openHelpMenuRequest');
    },
    addNewAudience() {
      this.$modal.show(
        AddCollegueDetail,
        {
          origin: TD_HYBRID_SEARCH,
        },
        AddNewAudienceModalProps,
      );
    },
    getSlidesMetadata(list) {
      if (list.length > 0) {
        const arr = list.map((item) => item.unique_id);
        const params = {
          assetIds: arr,
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        getKnowSlideMetaData(params)
          .then((slideData) => {
            this.knowMetadata = { ...this.knowMetadata, ...slideData.data };
            // this.setSlidesMeta(this.knowMetadata);
          })
          .catch((err) => console.log(err));
      }
    },
    isEligibleWalkthrough(item) {
      if (item.stype === 'S_WALKTHROUGH') {
        const eligibleWalkthrough = this.eligibleWalkthroughs.find(
          (wt) => wt.uuid.toLowerCase() === item.item.filename.toLowerCase(),
        );
        return !!eligibleWalkthrough;
      }
      return true;
    },
    async getWalkthroughs() {
      return await HelpApi.methods.getHelpData('walkthroughs').then(
        (resp) => {
          const eligibleWalkthroughs = resp.data.filter(
            (wt) =>
              wt.role === 'all' ||
              wt.role.toLocaleLowerCase() ===
                this.currentUser.user.role.toLocaleLowerCase(),
          );
          return eligibleWalkthroughs;
        },
        (error) => {
          console.log(error.response);
        },
      );
    },
    async goToTemplateTab(theme) {
      trackSearchEvents.hybridSearchAssetClick(this.currentUser, {
        [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
        [TD_QUERY]: this.savedQueryPayload?.query,
        [TD_TEMPLATE]: theme.name,
        [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_TEMPLATES,
      });
      const payload = {
        type: 'Template',
        value: theme.name,
        subvalue: theme.name,
        attributes: `/profile?tab=templates&query=${theme.name}`,
        object: theme,
      };
      this.updateRecentQueries(payload);
      this.$router.push(`/profile?tab=templates&query=${theme.name}`, () => {});
    },
    goToNewPrezentation() {
      this.$router.push(`/home/build`, () => {});
    },
    async handleClickHelp(item) {
      trackSearchEvents.trackHybridSearchHelp(this.currentUser, {
        type: item.type.toLowerCase().replace('-', ''),
        searchKey: this.searchQuery,
        hybridchip: this.selectedChip,
        item,
      });
      const payload = {
        type: item.type,
        value: item.name,
        subvalue: item.name,
        attributes: `/help/${item.type
          .toLowerCase()
          .replace('-', '')}?id=${item.item.id.toLowerCase()}`,
        object: item.item,
      };
      this.setIsSearchPage(true);
      this.updateRecentQueries(payload);
      this.$router.push(
        `/help/${item.type
          .toLowerCase()
          .replace('-', '')}?id=${item.item.id.toLowerCase()}`,
      );
    },
    getFilename(item) {
      if (item && item.filename) {
        return item.filename;
      }
      if (item.mergedSlides) {
        return item.mergedSlides;
      }
      return '';

      // return item && item.filename ? item.filename : item.mergedSlides ? item.mergedSlides : "";
    },
    capitalizeFirstLetter(term) {
      return term.charAt(0).toUpperCase() + term.slice(1);
    },
    getThumbsUpCount(asset) {
      const votes = this.assetVotes.filter(
        (vote) => vote.assetID === asset.name && vote.voteType === 'upvote',
      );
      if (!votes) return 0;
      return votes.length;
    },
    getThumbsDownCount(asset) {
      const votes = this.assetVotes.filter(
        (vote) => vote.assetID === asset.name && vote.voteType === 'downvote',
      );
      if (!votes) return 0;
      return votes.length;
    },
    getDownloadFilename(item) {
      let fileName = '';
      if (item.name && item.type) {
        fileName = `${item.name}_${item.type}`;
      } else {
        fileName = `${item.categories[0]}_${item.prefs.theme}`;
      }
      return `${fileName}.pptx`;
    },

    goToFavSldLib() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavSlides',
        origin: 'fav_slides',
      });
      this.$router.push('/home/slides').catch(() => {});
    },

    /*
      Function to find template object
      based on current slide associated template code
    */
    getTemplateForSlideLoaded(data) {
      const template = this.themes.find(
        (thm) => thm.code === this.getTemplateCodeFromSlideLoaded(data),
      );
      return template;
    },

    /*
      Function to return the slide object related theme code
      based on type of slide
    */
    getTemplateCodeFromSlideLoaded(data) {
      if (
        ['generated', 'uploaded', 'synthesis', 'redesign', 'comply'].includes(
          data?.prefs?.source,
        )
      ) {
        return data?.templateCode || '';
      }
      return data?.prefs?.theme || '';
    },

    async handleFavorite(item, origin) {
      const favorite = this.getFavoriteSlides.includes(item.asset.unique_id);
      if (favorite || item.isFavorite) {
        let favSlideId;
        if (origin === 'uploaded') {
          favSlideId =
            this.uploadedSlidesMetaData[item.asset.unique_id].favouriteId;
        } else {
          favSlideId = this.slideMetaData[item.asset.unique_id].favouriteId;
        }
        this.addingToFavSlides.push({
          removing: true,
          id: item.asset.unique_id,
        });
        if (!favSlideId) {
          const params = {
            assetIds: [item.asset.unique_id],
            getFavourite: true,
            getViewCount: true,
          };
          const resp = await getSlideDetail(params);
          favSlideId = resp.data[item.asset.unique_id].favouriteId;
        }
        await deleteFavorite(favSlideId)
          .then(() => {
            const index = this.getFavoriteSlides.indexOf(item.asset.unique_id);
            if (index > -1) this.removeFromFavoriteSlides(item.asset.unique_id);
          })
          .catch((err) => console.log(err));
        this.addingToFavSlides = [...this.addingToFavSlides].filter(
          (itm) => itm.id !== item.asset.unique_id,
        );
      } else {
        const favoriteData = {
          id: uuidv4(),
          assetID: item.asset.unique_id,
          source: item.asset.prefs.source,
          themeID: this.getTemplateCodeFromSlideLoaded(item.asset),
          tailorID: this.currentUser?.user?.id,
          audienceType: 'user',
        };
        this.addingToFavSlides.push({ adding: true, id: item.asset.unique_id });
        await createFavorite(favoriteData)
          .then(() => {
            this.addToFavoriteSlides(item.asset.unique_id);

            this.setAddedToLibOrFavSnackbar({
              show: true,
              ctaText: 'snackBarItems.goToFavorites',
              snackbarText: 'snackBarItems.slideAddedToFav',
              navFn: this.goToFavSldLib,
              snackbarType: 'goToFav',
            });
          })
          .catch((err) => console.log(err));
        this.addingToFavSlides = [...this.addingToFavSlides].filter(
          (itm) => itm.id !== item.asset.unique_id,
        );
        const assetIds = [...this.slides].map((sld) => sld.unique_id);
        let beforeInteractionSlides = [];
        let afterInteractionSlides = [];
        if (assetIds.length) {
          const itemToRemove = item.asset
            ? item.asset.unique_id
            : item.unique_id;
          const itemIndexToRemove = assetIds.indexOf(itemToRemove);
          if (itemIndexToRemove !== -1) {
            beforeInteractionSlides = assetIds.slice(0, itemIndexToRemove);
            afterInteractionSlides = assetIds.slice(itemIndexToRemove + 1);
          }
        }
        const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
        const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
        const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
        trackSearchEvents.searchHybridSlideFavorited(
          item.asset,
          this.currentUser,
          {
            [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
            [TD_QUERY]: this.savedQueryPayload?.query,
            [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_SLIDES,
            [TD_ISFAVORITED]: 1,
            [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
            [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
            [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
          },
        );
        AnalyticsHandler.markedSlideFavorite(this.currentUser, item.unique_id);
      }
      await this.fetchCurrentUserFavorites(this.currentUser.id);
    },
    async getUploadedSlidesDetails(endIndex) {
      const ids = [];
      let slidesReqArray = [];
      console.log(this.allUploadedSlides);
      console.log(this.uploadedSlides);
      if (endIndex) {
        slidesReqArray = this.allUploadedSlides.slice(
          this.startUploadedIndex,
          endIndex,
        );
      } else {
        slidesReqArray = this.uploadedSlides.slice(
          this.startUploadedIndex,
          endIndex,
        );
      }
      slidesReqArray.forEach((ele) => {
        ids.push(ele.parent.sid);
      });
      console.log(slidesReqArray);
      const params = {
        ids,
        limit: 2000,
        offset: 0,
        slideTypeString: 'all',
        categories: '',
        createdFromString: 'uploaded',
        createdOnFromDate: '',
        createdOnToDate: '',
        authorString: 'all',
        accessString: 'all',
        templateCode: this.currentUser?.theme?.code || 'default',
        origin: 'uploaded',
      };
      const slideData = await getGeneratedUploadedSlides(params);
      this.uploadedSlides = [...this.uploadedSlides, ...slideData.data.slides];
      console.log(slideData);
    },
    async getSlideDetails(origin, endIndex) {
      let slidesReqArray = [];
      if (endIndex) {
        slidesReqArray = this.allSlides.slice(this.startIndex, endIndex);
      } else {
        slidesReqArray = this.slides.slice(this.startIndex, endIndex);
      }
      let arr = [];
      arr = slidesReqArray.map((item) => item.unique_id);
      const params = {
        assetIds: arr,
        getDownload: true,
        getFavourite: true,
        getTrending: true,
        getViewCount: true,
      };
      const slideData = await getSlideDetail(params);
      this.slideMetaData = { ...this.slideMetaData, ...slideData.data };
      this.startIndex += this.brandSlidePageSize;
      for (const key in this.slideMetaData) {
        if (
          this.slideMetaData[key].isDownloaded === true &&
          !this.getDownloadedSlides.includes(key)
        ) {
          this.addToDownloadedSlides(key);
        }
      }
      for (const key in this.slideMetaData) {
        if (
          this.slideMetaData[key].isImageReplaced === true &&
          !this.getImageReplacedSlides.includes(key)
        ) {
          this.addToImageReplacedSlides(key);
        }
      }
      for (const key in this.slideMeta) {
        if (
          this.slideMetaData[key].isIconReplaced === true &&
          !this.getIconReplacedSlides?.includes(key)
        ) {
          this.addToIconReplacedSlides(key);
        }
      }
      for (const key in this.slideMetaData) {
        if (
          this.slideMetaData[key].isFavourite === true &&
          !this.getFavoriteSlides.includes(key)
        ) {
          this.addToFavoriteSlides(key);
        } else if (
          (!this.slideMetaData[key].isFavourite ||
            this.slideMetaData[key].isFavourite === false) &&
          this.getFavoriteSlides.includes(key)
        ) {
          this.removeFromFavoriteSlides(key);
        }
      }
      for (const key in this.slideMetaData) {
        if (
          this.slideMetaData[key].isTrending === true &&
          !this.trendingSlides.includes(key)
        ) {
          this.trendingSlides.push(key);
        }
      }
    },
    async getUploadedSlidesDetailss(endIndex) {
      let slidesReqArray = [];
      if (endIndex) {
        slidesReqArray = this.allUploadedSlides.slice(
          this.startUploadedIndex,
          endIndex,
        );
      } else {
        slidesReqArray = this.uploadedSlides.slice(
          this.startUploadedIndex,
          endIndex,
        );
      }
      let arr = [];
      arr = slidesReqArray.map((item) => item.unique_id);
      const params = {
        assetIds: arr,
        getDownload: true,
        getFavourite: true,
        getTrending: true,
        getViewCount: true,
      };
      const slideData = await getSlideDetail(params);
      this.uploadedSlidesMetaData = {
        ...this.uploadedSlidesMetaData,
        ...slideData.data,
      };
      // this.startUploadedIndex += this.slidesPageSize;
      for (const key in this.uploadedSlidesMetaData) {
        if (
          this.uploadedSlidesMetaData[key].isDownloaded === true &&
          !this.getDownloadedSlides.includes(key)
        ) {
          this.addToDownloadedSlides(key);
        }
      }
      for (const key in this.uploadedSlidesMetaData) {
        if (
          this.uploadedSlidesMetaData[key].isImageReplaced === true &&
          !this.getImageReplacedSlides.includes(key)
        ) {
          this.addToImageReplacedSlides(key);
        }
      }
      for (const key in this.uploadedSlidesMetaData) {
        if (
          this.uploadedSlidesMetaData[key].isIconReplaced === true &&
          !this.getIconReplacedSlides.includes(key)
        ) {
          this.addToIconReplacedSlides(key);
        }
      }
      for (const key in this.uploadedSlidesMetaData) {
        if (
          this.uploadedSlidesMetaData[key].isFavourite === true &&
          !this.getFavoriteSlides.includes(key)
        ) {
          this.addToFavoriteSlides(key);
        } else if (
          (!this.uploadedSlidesMetaData[key].isFavourite ||
            this.uploadedSlidesMetaData[key].isFavourite === false) &&
          this.getFavoriteSlides.includes(key)
        ) {
          this.removeFromFavoriteSlides(key);
        }
      }
      for (const key in this.uploadedSlidesMetaData) {
        if (
          this.uploadedSlidesMetaData[key].isTrending === true &&
          !this.trendingSlides.includes(key)
        ) {
          this.trendingSlides.push(key);
        }
      }
    },
    currentRoute() {
      return this.$route.path;
    },
    async handleLearnClick(item) {
      trackSearchEvents.hybridSearchAssetClick(this.currentUser, {
        [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
        [TD_QUERY]: this.savedQueryPayload?.query,
        [TD_LEARNCOURSENAME]: item.title,
        [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_LEARN,
      });
      MatomoAnalyticsHandler.searchLearn({
        title: item?.title,
        origin: 'hybrid',
        isExact: this.searchQuery === item?.etags?.lm_title,
        user: this.currentUser,
        searchKeyword: this.searchQuery,
      });
      const payload = {
        type: 'Learn',
        value: item.decodedTitle,
        subvalue: item.decodedTitle,
        attributes: `/home/learn/course/${item.etags.lm_course_id}?assetid=${item.filename}`,
        object: item,
      };
      this.updateRecentQueries(payload);
      this.$router.push(
        `/home/learn/course/${item.etags.lm_course_id}?assetid=${item.filename}`,
      );
    },
    doTemplateSearch(payload) {
      // const filteredArray = this.themes.filter((theme) =>
      //   this.isSubArray(theme, payload),
      // );
      const filteredArray = this.themes.filter((theme) =>
        theme?.name.toLowerCase().includes(payload.toLowerCase()),
      );
      this.allTemplatesFiltered = filteredArray;
      this.templatesFiltered = this.allTemplatesFiltered.slice(0, 6);
      if (this.templatesFiltered.length) {
        this.searchResultFoundCount++;
      }
      this.searchResultCompletedInfo.template = true;
    },
    isSubArray(theme, payload) {
      return (
        `${theme.name.split(' ').join(',')},`
          .toLowerCase()
          .indexOf(`${payload.split(' ').join(',')},`.toLowerCase()) !== -1
      );
    },
    displayBadgeInTheme(theme) {
      if (theme) {
        const currentUserCreatedDate = this.currentUser?.user?.createdAt;
        const currentDate = moment(new Date());
        const expiryInDays = 15;
        const templateReleaseDate = moment(theme.createdAt, 'YYYY-MM-DD');
        if (moment(currentUserCreatedDate).isAfter(templateReleaseDate)) {
          return false;
        }
        const duration = moment.duration(currentDate.diff(templateReleaseDate));
        return duration.asDays() >= 0 && duration.asDays() <= expiryInDays;
      }
      return false;
    },
    handleClickTheme(theme) {
      if (theme.code === this.currentTheme.code) {
        return;
      }
      this.$modal.show(
        SlideThemeChangeDetail,
        {
          index: theme,
          updateThemeIndex: this.updateThemeIndex,
        },
        {
          name: 'SlideThemeChangeDetail',
          width: '540px',
          height: '165px',
          styles: { borderRadius: '18px' },
        },
      );
    },
    async updateThemeIndex(theme) {
      try {
        MatomoAnalyticsHandler.templateSwitched(this.currentUser, theme);

        await usersApi.methods
          .updateUserProfile({ defaultTheme: theme.code })
          .then(async (updatedUserDetail) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetail;
            await this.setCurrentUser(userInfo);
            this.currentTheme = theme;
            await this.setCurrentTheme(theme);
            this.$router.push('/profile?tab=templates', () => {});
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getUsersFromAssociatedCompanies(companyId) {
      return API.graphql({
        query: userByCompany,
        variables: { companyID: companyId, limit: 1000 },
      });
    },

    getSlideBasedTemplateForDownload(code) {
      const template = this.themes.find((thm) => thm.code === code);
      if (template) {
        return template.name;
      }
      return code;
    },

    async handleDownloadFromList(reDownload, item, fromExpandedView, origin) {
      AnalyticsHandler.slideDownloadStarted(this.currentUser, item.asset);
      AnalyticsHandler.logUserStats(this.currentUser);
      if (item.mergedSlides) {
        let fileName = '';
        if (item.name && item.type) {
          fileName = `${item.name} - ${item.type}`;
        } else {
          fileName = `${this.capitalizeFirstLetter(
            item.title || item.categories[0],
          )} - ${this.getSlideBasedTemplateForDownload(item.prefs.theme)}`;
        }

        const s3url = `${miscConfig.aws_prezentation_distribution}/${item.mergedSlides}`;

        const slideid =
          item.prefs.source === 'uploaded' ||
          item.prefs.source === 'generated' ||
          item.prefs.source === 'synthesis' ||
          item.prefs.source === 'redesign' ||
          item.prefs.source === 'comply'
            ? item.id
            : item.unique_id;
        this.setShowDownloadSnackbar(true);
        this.setDownloadLoaders({
          loaderId: slideid,
          downloading: true,
        });
        this.setDownloadItems({
          status: 'assembling',
          itemIndex: uuidv4(),
          originType: 'slideLibCardHybridSearchMergeSlide',
          logDownloadCallback: async () => {
            await this.logDownload(null, item, origin);

            AnalyticsHandler.slideDownloadComplete(this.currentUser, {
              unique_id: item.id,
            });
          },
          mergeSlidesCallback: () => {},
          downloadStatusCallback: () => {
            this.setDownloadLoaders({
              loaderId: slideid,
              downloading: false,
            });
          },
          metaData: {
            item: { ...item },
            filePath: s3url,
            fileName,
            limitsKey: 'slide',
            origin: 'slide',
          },
        });

        // ToDo
        // 1. Change the following to signed url and remove hardcoding
        // 2. Update download count
        // 3. Update the google drive download button
        // const s3url = `${miscConfig.aws_prezentation_distribution}/${item.mergedSlides}`;
        // AnalyticsHandler.slideDownloadComplete(this.currentUser, {
        //   unique_id: item.id,
        // });
        // await this.logDownload(null, item, origin);

        // let fileName = '';
        // if (item.name && item.type) {
        //   fileName = `${item.name}_${item.type}`;
        // } else {
        //   fileName = `${item.categories[0]}_${item.prefs.theme}`;
        // }
        // downloadFileWithCustomName(s3url, fileName);

        // window.location.href = s3url;
      } else {
        // downloading slides with common download snackbar
        const filePath = item.asset ? item.asset.filename : item.filename;

        let fileName = '';
        if (item.asset) {
          fileName = `
          ${
            item.asset.prefs &&
            item.asset.prefs.source &&
            (item.asset.prefs.source === 'uploaded' ||
              item.asset.prefs.source === 'generated' ||
              item.asset.prefs.source === 'synthesis' ||
              item.asset.prefs.source === 'redesign' ||
              item.asset.prefs.source === 'comply')
              ? item.asset.name
              : `${this.capitalizeFirstLetter(
                  item.asset.title || item.asset.categories[0],
                )} - ${this.getSlideBasedTemplateForDownload(
                  item.asset.prefs.theme,
                )}`
          }`;
        } else {
          fileName = `${
            item.prefs &&
            item.prefs.source &&
            (item.prefs.source === 'uploaded' ||
              item.prefs.source === 'generated' ||
              item.prefs.source === 'synthesis' ||
              item.prefs.source === 'redesign' ||
              item.prefs.source === 'comply')
              ? item.name
              : `${this.capitalizeFirstLetter(
                  item.title || item.categories[0],
                )} - ${this.getSlideBasedTemplateForDownload(item.prefs.theme)}`
          }`;
        }

        const slideid =
          item.prefs.source === 'uploaded' ||
          item.prefs.source === 'generated' ||
          item.prefs.source === 'synthesis' ||
          item.prefs.source === 'redesign' ||
          item.prefs.source === 'comply'
            ? item.id
            : item.unique_id;
        this.setShowDownloadSnackbar(true);
        this.setDownloadLoaders({
          loaderId: slideid,
          downloading: true,
        });
        this.setDownloadItems({
          status: 'assembling',
          itemIndex: uuidv4(),
          originType: 'slideLibCardHybridSearch',
          logDownloadCallback: async () => {
            await this.logDownload(null, item, origin);

            const analyticsItem = item.asset ? item.asset : item;
            const { slideTitle, slideId } = utils.getSlideTitleAndID(item);
            try {
              const assetIds = [...this.slides].map((sld) => sld.unique_id);
              let beforeInteractionSlides = [];
              let afterInteractionSlides = [];
              if (assetIds.length) {
                const itemToRemove = item.asset
                  ? item.asset.unique_id
                  : item.unique_id;
                const itemIndexToRemove = assetIds.indexOf(itemToRemove);
                if (itemIndexToRemove !== -1) {
                  beforeInteractionSlides = assetIds.slice(
                    0,
                    itemIndexToRemove,
                  );
                  afterInteractionSlides = assetIds.slice(
                    itemIndexToRemove + 1,
                  );
                }
              }
              const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
              const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
              const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
              const otherData = {
                ...this.getCommonOtherDataForSlideDownload(item),
                [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
                [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
                [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
                [TD_HYBRID_RESULTS_SECTION_CLICK]:
                  TD_HYBRID_RESULTS_SECTION_SLIDES,
                [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
              };
              trackSearchEvents.hybridSlides(item, this.currentUser, otherData);
            } catch (e) {
              console.log(e);
            }

            if (fromExpandedView) {
              MatomoAnalyticsHandler.hybridSearchSlidesDownloadedExpandedView(
                slideTitle,
                slideId,
                this.currentUser,
                {
                  audience: this.getAudienceDataToTrack(),
                },
              );
            } else {
              MatomoAnalyticsHandler.hybridSearchSlidesDownloadedThumbnail(
                slideTitle,
                slideId,
                this.currentUser,
                {
                  audience: this.getAudienceDataToTrack(),
                },
              );
            }
            AnalyticsHandler.slideDownloadComplete(
              this.currentUser,
              analyticsItem,
            );
          },
          mergeSlidesCallback: () => {},
          downloadStatusCallback: () => {
            this.setDownloadLoaders({
              loaderId: slideid,
              downloading: false,
            });
          },
          metaData: {
            item: { ...item },
            filePath,
            fileName,
            limitsKey: 'slide',
            origin: 'slide',
          },
        });

        // const filePath = item.asset ? item.asset.filename : item.filename;
        // this.downloadingSlides.push({
        //   downloading: true,
        //   id: item.unique_id,
        // });
        // await this.logDownload(null, item, origin);

        // const analyticsItem = item.asset ? item.asset : item;
        // const { slideTitle, slideId } = utils.getSlideTitleAndID(item);
        // try {
        //   const assetIds = [...this.slides].map((sld) => sld.unique_id);
        //   let beforeInteractionSlides = [];
        //   let afterInteractionSlides = [];
        //   if (assetIds.length) {
        //     const itemToRemove = item.asset
        //       ? item.asset.unique_id
        //       : item.unique_id;
        //     const itemIndexToRemove = assetIds.indexOf(itemToRemove);
        //     if (itemIndexToRemove !== -1) {
        //       beforeInteractionSlides = assetIds.slice(0, itemIndexToRemove);
        //       afterInteractionSlides = assetIds.slice(itemIndexToRemove + 1);
        //     }
        //   }
        //   const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
        //   const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
        //   const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
        //   const otherData = {
        //     ...this.getCommonOtherDataForSlideDownload(item),
        //     [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
        //     [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
        //     [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
        //     [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_SLIDES,
        //     [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
        //   };
        //   trackSearchEvents.hybridSlides(item, this.currentUser, otherData);
        // } catch (e) {
        //   console.log(e);
        // }

        // if (fromExpandedView) {
        //   MatomoAnalyticsHandler.hybridSearchSlidesDownloadedExpandedView(
        //     slideTitle,
        //     slideId,
        //     this.currentUser,
        //     {
        //       audience: this.getAudienceDataToTrack(),
        //     },
        //   );
        // } else {
        //   MatomoAnalyticsHandler.hybridSearchSlidesDownloadedThumbnail(
        //     slideTitle,
        //     slideId,
        //     this.currentUser,
        //     {
        //       audience: this.getAudienceDataToTrack(),
        //     },
        //   );
        // }
        // AnalyticsHandler.slideDownloadComplete(this.currentUser, analyticsItem);

        // const fileType = 'normal';
        // let fileUrl = '';
        // if (item.prefs.source === 'uploaded') {
        //   fileUrl = await getFileURL(
        //     this.currentUser.user.id,
        //     filePath,
        //     this.currentUser.userIp,
        //     'IOCUpload',
        //   );
        // } else if (
        //   item.prefs.source === 'generated' ||
        //   item.prefs.source === 'synthesis' ||
        //   item.prefs.source === 'redesign'
        // ) {
        //   if (
        //     filePath.startsWith('/newImageReplaceOutput/output/') ||
        //     filePath.startsWith('/output/pptx/')
        //   ) {
        //     fileUrl = await getFileURLDownload(
        //       this.currentUser.user.id,
        //       filePath,
        //       this.currentUser.userIp,
        //     );
        //   } else {
        //     fileUrl = await getFileURL(
        //       this.currentUser.user.id,
        //       filePath,
        //       this.currentUser.userIp,
        //       'IOCUpload',
        //     );
        //   }
        // } else {
        //   fileUrl = await getFileURL(
        //     this.currentUser.user.id,
        //     filePath,
        //     this.currentUser.userIp,
        //   );
        // }

        // let fileName = '';
        // if (item.asset) {
        //   fileName = `
        //   ${
        //     item.asset.prefs &&
        //     item.asset.prefs.source &&
        //     (item.asset.prefs.source === 'uploaded' ||
        //       item.asset.prefs.source === 'generated' ||
        //       item.asset.prefs.source === 'synthesis' ||
        //       item.asset.prefs.source === 'redesign' ||
        //       item.asset.prefs.source === 'comply')
        //       ? item.asset.name
        //       : `${item.asset.categories[0]}_${item.asset.prefs.theme}`
        //   }`;
        // } else {
        //   fileName = `${
        //     item.prefs &&
        //     item.prefs.source &&
        //     (item.prefs.source === 'uploaded' ||
        //       item.prefs.source === 'generated' ||
        //       item.prefs.source === 'synthesis' ||
        //       item.prefs.source === 'redesign' ||
        //       item.prefs.source === 'comply')
        //       ? item.name
        //       : `${item.categories[0]}_${item.prefs.theme}`
        //   }`;
        // }

        // downloadFileWithCustomName(fileUrl, fileName);
        // console.log(fileUrl, fileName);
        // const isRestricted =
        //   item?.prefs?.isRestricted ||
        //   item?.prefs?.permission_level === 0 ||
        //   item?.permission === 'Restricted';
        // let mergePath;
        // if (isRestricted) {
        //   const s = {
        //     outputFormat: 'pdf',
        //     outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        //     slides: [
        //       {
        //         slideId:
        //           item.filename.charAt(0) === '/'
        //             ? item.filename.substring(1)
        //             : item.filename,
        //         isReplaced:
        //           item.filename.startsWith('/newImageReplaceOutput/output/') ||
        //           item.filename.startsWith('/output/pptx/'),
        //         isUploaded: item.prefs.source === 'uploaded',
        //         isGenerated: item.prefs.source === 'generated',
        //         isSynthesis: item.prefs.source === 'synthesis',
        //         isRedesign: item.prefs.source === 'redesign',
        //         isComply: item.prefs.source === 'comply',
        //       },
        //     ],
        //   };
        //   await callMergeSlidesApi(s)
        //     .then((res) => {
        //       const mergedslide = res.data;
        //       // if (mergedslide.includes('.pptx')) {
        //       mergePath = `${mergedslide}`;
        //       // }
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //       this.downloadingSlide = false;
        //     });
        // }

        // await downloadFileWithAPI(
        //   fileType,
        //   isRestricted ? mergePath : filePath,
        //   `${fileName}${isRestricted ? '.pdf' : '.pptx'}`,
        //   item?.prefs?.source,
        // );
        // this.downloadingSlides = [...this.downloadingSlides].filter(
        //   (itm) => itm.id !== item.unique_id,
        // );
      }
    },

    getAudienceDataToTrack() {
      try {
        return {
          email: this.currentUser?.user?.id,
        };
      } catch (e) {
        console.log(e);
        return {
          email: '',
        };
      }
    },

    getCommonOtherDataForSlideDownload(item) {
      return {
        [TD_ISDOWNLOAD]: 1,
        [TD_ISFAVORITED]: isSlideFavorite(item, this.getFavoriteSlides)
          ? '1'
          : NA,
        [TD_PEOPLERESULTS]: this.people?.length,
        [TD_KNOWRESULTS]: this.knowSlides?.length,
        [TD_LEARNRESULTS]: this.learn?.length,
        [TD_SLIDESRESULTS]: this.slides?.length,
        [TD_HELPRESULTS]: this.help?.length,
        [TD_PREZENTATIONRESULTS]: this.prezentations?.length,
        [TD_QUERY]: this.searchQuery,
        [TD_SLIDEID]: getSlideId(item),
      };
    },

    async logDownload(
      fromExpandedViewObj,
      item,
      origin,
      imageReplaced = false,
      iconReplaced = false,
    ) {
      console.log('item ===>', item);
      let downloadData;
      if (item.prefs.source === 'uploaded') {
        downloadData = {
          id: uuidv4(),
          assetID: item.asset ? item.asset.unique_id : item.unique_id,
          construct: item.prefs.construct,
          downloadedFrom: 'Slides',
          isOverightPrez: false,
          source: 'uploaded',
          sanitizedLevel: item.sanitizedLevel,
          acceptedRejectedCount: item.acceptedRejectedCount,
          tailorID: this.currentUser.user.id,
          themeID: this.getTemplateCodeFromSlideLoaded(item.asset || item),
          isRestricted: item.permission === 'Restricted',
        };
      } else {
        downloadData = {
          id: uuidv4(),
          userID: this.currentUser.user.id,
          assetID: item.asset ? item.asset.unique_id : item.unique_id,
          companyID: this.currentUser.user.companyID,
          themeID: this.getTemplateCodeFromSlideLoaded(item.asset || item),
          createdAt: new Date().toISOString(),
          construct: item.prefs.construct,
          idea: item.title,
          tailorID: this.currentUser.user.id,
          downloadedFrom: 'Slides',
          isRestricted: item.permission === 'Restricted',
          source: item.prefs?.source,
        };
      }
      if (imageReplaced) {
        downloadData.replacedImage = true;
      }
      if (iconReplaced) {
        downloadData.replacedIcon = true;
      }
      await createDownload(downloadData)
        .then(() => {
          if (imageReplaced && iconReplaced) {
            this.addToImageReplacedSlides(item.unique_id);
            this.addToIconReplacedSlides(item.unique_id);
          } else if (imageReplaced) {
            this.addToImageReplacedSlides(item.unique_id);
          } else if (iconReplaced) {
            this.addToIconReplacedSlides(item.unique_id);
          } else {
            this.addToDownloadedSlides(item.unique_id);
          }
        })
        .catch((err) => console.log(err));
      this.currentUser.user.downloadUsed++;
      const slidesCount = item.mergedSlides ? item.mergedSlides.length : 1;
      const downloadMetric = this.getDownloadMetric(
        item.prefs.source,
        item.type,
      );
      const userData = {
        performIncrements: {
          [downloadMetric]: slidesCount,
        },
      };
      if (downloadMetric === 'sanitizedSlidesDownloaded') {
        userData.performIncrements.uploadSlidesDownloaded = slidesCount;
      }
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(userInfo);
        })
        .catch((err) => console.log(err));
      // await PrezentAnalyticsAPI.incrementSlideDownloadCount(slidesCount);
      if (fromExpandedViewObj) {
        const { slideTitle, slideId } = utils.getSlideTitleAndID(item);
        try {
          const assetIds = [...this.slides].map((sld) => sld.unique_id);
          let beforeInteractionSlides = [];
          let afterInteractionSlides = [];
          if (assetIds.length) {
            const itemToRemove = item.asset
              ? item.asset.unique_id
              : item.unique_id;
            const itemIndexToRemove = assetIds.indexOf(itemToRemove);
            if (itemIndexToRemove !== -1) {
              beforeInteractionSlides = assetIds.slice(0, itemIndexToRemove);
              afterInteractionSlides = assetIds.slice(itemIndexToRemove + 1);
            }
          }
          const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
          const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
          const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
          const otherData = {
            ...this.getCommonOtherDataForSlideDownload(item),
            [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_GS,
            [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
            [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
            [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_SLIDES,
            [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
          };
          trackSearchEvents.hybridSlides(item, this.currentUser, otherData);
        } catch (e) {
          console.log(e);
        }
        if (fromExpandedViewObj.fromExpandedView) {
          MatomoAnalyticsHandler.hybridSearchSlidesDownloadedGSExpandedView(
            slideTitle,
            slideId,
            this.currentUser,
            {
              audience: this.getAudienceDataToTrack(),
            },
          );
        } else {
          MatomoAnalyticsHandler.hybridSearchSlidesDownloadedGSThumbnail(
            slideTitle,
            slideId,
            this.currentUser,
            {
              audience: this.getAudienceDataToTrack(),
            },
          );
        }
      }
    },
    handleMore(list, allList, type) {
      if (list.length < allList.length) {
        const startIndex = 0;
        let endIndex = 9;
        if (type === 'audience') {
          endIndex = Math.min(
            allList.length,
            list.length + this.audiencePageSize,
          );
        } else if (type === 'slides') {
          endIndex = Math.min(
            allList.length,
            list.length + this.slidesPageSize,
          );
        } else if (type === 'brand_slides') {
          endIndex = Math.min(
            allList.length,
            list.length + this.brandSlidePageSize,
          );
        } else if (type === 'templates') {
          endIndex = Math.min(allList.length, list.length + 6);
        } else if (type === 'help') {
          endIndex = Math.min(allList.length, list.length + 8);
        } else if (type === 'learn') {
          endIndex = Math.min(allList.length, list.length + 4);
        } else {
          endIndex = Math.min(allList.length, list.length + this.pageSize);
        }
        console.log(list.length, allList.length);
        console.log(endIndex);
        list = allList.slice(startIndex, endIndex);
      }
      return list;
    },
    handleLoadMorePrezentations() {
      this.prezentations = this.handleMore(
        this.prezentations,
        this.allPrezentations,
      );
    },
    handleLoadMoreLearn() {
      this.learn = this.handleMore(this.learn, this.allLearn, 'learn');
    },
    handleLoadMoreAudience() {
      this.people = this.handleMore(this.people, this.allPeople, 'audience');
    },
    handleLoadMoreHelp() {
      this.help = this.handleMore(this.help, this.allHelp, 'help');
    },
    handleLoadMoreSlides() {
      this.slides = this.handleMore(
        this.slides,
        this.allSlides,
        'brand_slides',
      );
      this.getSlideDetails('brand_slides', this.slides.length - 1);
    },
    async handleLoadMoreUploadedSlides() {
      const slides = this.handleMore(
        this.uploadedSlides,
        this.allUploadedSlides,
        'slides',
      );
      console.log(slides);
      await this.getUploadedSlidesDetailss(slides.length);
      await this.getUploadedSlidesDetails(slides.length);
      this.startUploadedIndex += this.slidesPageSize;
    },
    handleLoadMoreKnowSlides() {
      this.knowSlides = this.handleMore(
        this.knowSlides,
        this.knowAllSlides,
        'slides',
      );
    },
    handleLoadMoreOPTickets() {
      if (!this.allOPResultsLoaded()) {
        this.opPage += 1;
        this.loadOPTickets({
          query: this.searchTermToLog,
          page: this.opPage,
        }).then((res) => {
          this.opTickets = [...this.opTickets, ...this.formatOpRequests(res)];
        });
      }
    },
    disableNewReq() {
      return (
        !!this.getOPLimitedBanner ||
        ['disabled', 'limited_access'].includes(this.getOPAccessLevel) ||
        (this.getOPAccessLevel === 'restricted_access' &&
          this.currentUser.user.OPRequestCount > 0)
      );
    },
    handleLoadMoreTemplates() {
      this.templatesFiltered = this.handleMore(
        this.templatesFiltered,
        this.allTemplatesFiltered,
        'templates',
      );
    },
    handleLoadMoreStorylines() {
      this.filteredStorylines = this.handleMore(
        this.filteredStorylines,
        this.allFilteredStorylines,
      );
    },
    handleLoadMoreBuild() {
      this.filteredBuild = this.handleMore(
        this.filteredBuild,
        this.allFilteredStorylines,
      );
    },
    initialize() {
      this.selectedChip = this.$route.query?.chip
        ? this.$route.query?.chip
        : 'all';
    },
    clickChipIdeas(keyword) {
      try {
        const otherData = {
          [TD_HYBRIDCHIP]: keyword.name,
          [TD_QUERY]: this.searchQuery,
        };
        trackSearchEvents.hybridIdeaChipClick(this.currentUser, otherData);
      } catch (e) {
        console.log(e);
      }
      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (this.$route.query.chip !== keyword.name.toLowerCase()) {
        query.chip = keyword.name.toLowerCase();
        this.$router.replace({ query });
      }
      this.selectedChip = keyword.name.toLowerCase();
      this.savedQueryPayload = {
        ...this.savedQueryPayload,
        selectedChip: this.selectedChip,
      };
      this.setSavedQueryPayload(this.savedQueryPayload);
    },
    async gotoFingerPrint(item) {
      if (!item.isActive) return;
      trackSearchEvents.hybridSearchAssetClick(this.currentUser, {
        [TD_HYBRIDCHIP]: this.savedQueryPayload?.selectedChip,
        [TD_QUERY]: this.savedQueryPayload?.query,
        [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_PEOPLE,
      });
      AnalyticsHandler.fingerprintSearched(this.currentUser, item.id);
      MatomoAnalyticsHandler.searchHybridUserSelected(item.id);
      if (item.isShared && item.type !== 'user') {
        const payload = {
          type: 'People',
          value: item.fullName,
          subvalue: item.fullName,
          attributes: `name: 'fingerprints',
            query: {
              uuid: item.uuid,
              type: item.type === 'audience' ? 'audience' : 'private_audience',
            }`,
          object: { ...item, num_id: item?.id, id: item?.email },
        };
        this.updateRecentQueries(payload);
        this.$router
          .push({
            name: 'fingerprints',
            query: {
              uuid: item.uuid,
              type: item.type === 'audience' ? 'audience' : 'private_audience',
            },
          })
          .catch(() => {});
        return;
      }

      if (item.type === 'user') {
        const payload = {
          type: 'People',
          value: item.fullName,
          subvalue: item.fullName,
          attributes: `name: 'fingerprints',
            query: {
              username: item.email,
              type: 'user',
            }`,
          object: { ...item, num_id: item?.id, id: item?.email },
        };
        this.updateRecentQueries(payload);
        this.$router
          .push({
            name: 'fingerprints',
            query: {
              username: item.email,
              type: 'user',
            },
          })
          .catch(() => {});
        return;
      }

      if (item?.type?.toLowerCase() === 'standard') {
        const payload = {
          type: 'People',
          value: item.fullName,
          subvalue: item.fullName,
          attributes: `name: 'fingerprints',
            query: {
              username: item.email,
              type: 'standard',
            }`,
          object: { ...item, num_id: item?.id, id: item?.email },
        };
        this.updateRecentQueries(payload);
        this.$router
          .push({
            name: 'fingerprints',
            query: {
              username: item.email,
              type: 'standard',
            },
          })
          .catch(() => {});
        return;
      }

      if (item.type.toLowerCase() === 'private_audience') {
        const payload = {
          type: 'People',
          value: item.fullName,
          subvalue: item.fullName,
          attributes: `name: 'fingerprints',
          query: {
            username: item.email,
            type: 'private_audience',
          }`,
          object: { ...item, num_id: item?.id, id: item?.email },
        };
        this.updateRecentQueries(payload);
        this.$router.push({
          name: 'fingerprints',
          query: {
            username: item.email,
            type: 'private_audience',
          },
        });
        return;
      }

      if (item.isShared) {
        const payload = {
          type: 'People',
          value: item.fullName,
          subvalue: item.fullName,
          attributes: `name: 'fingerprints',
            query: {
              uuid: item.uuid,
            }`,
          object: { ...item, num_id: item?.id, id: item?.email },
        };
        this.updateRecentQueries(payload);
        this.$router
          .push({
            name: 'fingerprints',
            query: {
              uuid: item.uuid,
            },
          })
          .catch(() => {});
        return;
      }
      const payload = {
        type: 'People',
        value: item.fullName,
        subvalue: item.fullName,
        attributes: `name: 'fingerprints',
        query: {
          username: item.email,
          type: 'audience',
        }`,
        object: { ...item, num_id: item?.id, id: item?.email },
      };
      this.updateRecentQueries(payload);
      this.$router.push({
        name: 'fingerprints',
        query: {
          uuid: item.uuid,
          type: 'audience',
        },
      });
    },
    getName(item) {
      if (item.fullName) {
        return item.fullName;
      }
      if (item.firstName && item.lastName) {
        return getFullName(item.firstName, item.lastName);
      }
      if (item.firstName) {
        return getFullName(item.firstName);
      }
      return getFullName(item.userName);
    },
    setIntervalX(callback, delay, repetitions) {
      let x = 0;
      const intervalID = window.setInterval(() => {
        callback();

        if (++x === repetitions) {
          window.clearInterval(intervalID);
        }
      }, delay);
    },
    getRandomNumber(min, max) {
      const num = (Math.random() * (max - min) + min).toFixed(2);
      return Number(num);
    },
    getChoice(fingerprint) {
      let choice = {
        visual: this.getRandomNumber(4.5, 6.0),
        data: this.getRandomNumber(4.0, 5.0),
      };
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'performer':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'navigator':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'surgeon':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'architect':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scholar':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scientist':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;
        case 'producer':
          choice = { visual: 1.0, data: 1.0 };
          return choice;
        default:
          return choice;
      }
    },
    updateSearchCount() {
      const userData = {
        performIncrements: {
          countSearches: 1,
        },
      };
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUserDetails) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUserDetails;
          this.setCurrentUser(userInfo);
        })
        .catch((err) =>
          console.log('error while updating the search count', err),
        );
      AnalyticsHandler.logUserStats(this.currentUser);
    },
    async doKnowSearch(payload) {
      if (!this.isKnowEnabled) {
        return;
      }
      this.knowSlides.splice(0, this.knowSlides.length);
      this.knowAllSlides.splice(0, this.knowAllSlides.length);
      const savedQueryPayload = {
        andprefs: {
          theme: this.getThemeForBPEContent('hasBPGSlides'), // this.currentTheme.code,
          is_know: 'yes',
        },
        category: [],
        choice: {
          data: 4.61,
          visual: 5.97,
        },
        limit: 24,
        morprefs: {
          source: [],
        },
        notprefs: {
          category: [
            'nature',
            'mountain',
            '80s',
            'sports',
            '4th_of_july',
            'thanksgiving',
            'christmas',
            'sea',
            'movie',
            'space',
            'race_car',
            'prezfingerprinttest',
          ],
          source: [],
        },
        onlyids: false,
        orderby: 'CHOICE_DESC',
        orprefs: [],
        query: payload.query ? payload.query : '',
        skip: 0,
        username: this.currentUser.user.cognitoID,
      };
      this.searchKnowSlides = true;
      const serialized = JSON.stringify(savedQueryPayload);
      await GraphQLService.requestWithToken(
        graphqlOperation(performSearch, {
          parameters: serialized,
        }),
      )
        .then((res) => {
          const result = JSON.parse(res.data.performSearch);
          if (result.statusCode === 200) {
            const knowSlides = result.body.payloads;
            if (!knowSlides || knowSlides.length < 1) {
              this.knowSlides = [];
              this.knowAllSlides = [];
            } else {
              this.searchResultFoundCount++;
              this.knowAllSlides = knowSlides.sort((x, y) => {
                const yData =
                  y.title.toLowerCase() === payload.query.toLowerCase() ? 1 : 0;
                return x.title.toLowerCase() === payload.query.toLowerCase()
                  ? -1
                  : yData;
              });
              this.knowSlides = this.knowAllSlides.slice(
                0,
                this.slidesPageSize,
              );
              this.getSlidesMetadata(this.knowAllSlides);
            }
          } else {
            console.log(result);
          }
          this.searchResultCompletedInfo['best practice library'] = true;
        })
        .catch((err) => {
          console.log('err', err);
          this.searchProgress = false;
          this.searchResultCompletedInfo['best practice library'] = true;
        })
        .finally(() => {
          this.searchKnowSlides = false;
        });
    },
    async doOPSearch(text) {
      if (
        this.getOPAccessLevel &&
        this.getOPAccessLevel !== 'no_access' &&
        text.length
      ) {
        await this.loadOPTickets({ query: text, page: 1 }).then((res) => {
          this.opTickets = this.formatOpRequests(res);
          this.searchResultCompletedInfo.op = true;
          if (this.opTickets.length) {
            this.searchResultFoundCount++;
          }
        });
      }
    },
    async doSlideSearch(payload) {
      const choice = this.getChoice(
        this.currentUser.user.fingerPrint
          ? this.currentUser.user.fingerPrint
          : 'director',
      );
      console.log('payload', payload);
      this.searchSlides = true;
      this.slides.splice(0, this.slides.length);
      this.allSlides.splice(0, this.slides.length);
      const andprefs = {};
      andprefs.theme = this.currentUser?.theme?.code || 'default';
      const params = {
        username: this.currentUser.user.cognitoID,
        query: payload.query ? payload.query : '',
        orderby: 'DEFAULT',
        choice,
        // morprefs: payload.morprefs ? payload.morprefs : [],
        morprefs: [],
        prefs: payload.prefs ? payload.prefs : [],
        notprefs: payload.notprefs ? payload.notprefs : [],
        // company: this.currentUser.companyName.toLowerCase(),
        andprefs,
        // company: (payload.category && payload.category.length > 0 && payload.category.toLowerCase()==="agenda") || this.currentUser.organizationID.includes('test')? 'prezentium': 'prezentium',
        limit: payload.limit,
        skip: payload.skip >= 0 ? payload.skip : 0,
        slide_type: 'brand_slides',
      };
      const serialized = JSON.stringify(params);
      console.log('***********');
      console.log(`Audience: ${this.currentUser.user.cognitoID}`);
      console.log(`Audience fingerprint: ${this.currentUser.user.fingerPrint}`);
      console.log(`Query:${serialized}`);
      await GraphQLService.requestWithToken(
        graphqlOperation(performSearch, {
          parameters: serialized,
        }),
      )
        .then(async (res) => {
          const result = JSON.parse(res.data.performSearch);
          console.log('search results');
          console.log(result);
          if (result.statusCode === 200) {
            const slides = result.body.payloads;
            console.log('payload', slides);
            if (!slides || slides.length < 1) {
              MatomoAnalyticsHandler.searchNoResults(params.query);
              PendoAnalyticsHandler.noSearchResults({
                query: params.query,
                user: this.currentUser,
              });
              const otherData = {
                [TD_QUERY]: this.searchTermToLog,
                [TD_NORESULTSFOUND]: 1,
              };
              trackSearchEvents.hybrid(this.currentUser, otherData);
              this.slides = [];
              this.allSlides = [];
            } else {
              this.searchResultFoundCount++;
              this.allSlides = slides;
              this.slides = this.allSlides.slice(0, this.brandSlidePageSize);
              this.startIndex = 0;
              await this.getSlideDetails('brand_slides');
              const otherData = {
                [TD_QUERY]: this.searchTermToLog,
                [TD_NORESULTSFOUND]: 0,
              };
              trackSearchEvents.hybrid(this.currentUser, otherData);
            }
          } else {
            console.log(result);
          }
          this.searchSlides = false;
          this.searchResultCompletedInfo.slides = true;
        })
        .catch((err) => {
          console.log(err);
          this.searchResultCompletedInfo.slides = true;
          this.searchSlides = false;
        });

      this.updateSearchCount();
      this.setIntervalX(
        () => {
          this.cacheKey = +new Date();
        },
        500,
        1,
      );
    },
    async doUploadedSlideSearch(payload) {
      const choice = this.getChoice(
        this.currentUser.user.fingerPrint
          ? this.currentUser.user.fingerPrint
          : 'director',
      );
      console.log('payload', payload);

      this.uploadedSlides.splice(0, this.uploadedSlides.length);
      this.allUploadedSlides.splice(0, this.uploadedSlides.length);
      const andprefs = {};
      andprefs.templateCode = this.currentUser?.theme?.code || 'default';
      const params = {
        username: this.currentUser.user.cognitoID,
        query: payload.query ? payload.query : '',
        orderby: 'RELEVANCE',
        choice,
        // morprefs: payload.morprefs ? payload.morprefs : [],
        morprefs: [],
        prefs: payload.prefs ? payload.prefs : [],
        notprefs: payload.notprefs ? payload.notprefs : [],
        // company: this.currentUser.companyName.toLowerCase(),
        andprefs,
        // company: (payload.category && payload.category.length > 0 && payload.category.toLowerCase()==="agenda") || this.currentUser.organizationID.includes('test')? 'prezentium': 'prezentium',
        limit: payload.limit,
        skip: payload.skip >= 0 ? payload.skip : 0,
        slide_type: 'uploaded',
        is_recommender_search: false,
      };
      const serialized = JSON.stringify(params);
      console.log('***********');
      console.log(`Audience: ${this.currentUser.user.cognitoID}`);
      console.log(`Audience fingerprint: ${this.currentUser.user.fingerPrint}`);
      console.log(`Query:${serialized}`);

      await GraphQLService.requestWithToken(
        graphqlOperation(performSearch, {
          parameters: serialized,
        }),
      )
        .then((res) => {
          const result = JSON.parse(res.data.performSearch);
          console.log('search results');
          console.log(result);
          if (result.statusCode === 200) {
            const uploadedSlides = result.body.payloads;
            console.log('payload', uploadedSlides);
            if (!uploadedSlides || uploadedSlides.length < 1) {
              MatomoAnalyticsHandler.searchNoResults(params.query);
              PendoAnalyticsHandler.noSearchResults({
                query: params.query,
                user: this.currentUser,
              });
              const otherData = {
                [TD_QUERY]: this.searchTermToLog,
                [TD_NORESULTSFOUND]: 1,
              };
              trackSearchEvents.hybrid(this.currentUser, otherData);
              this.uploadedSlides = [];
              this.allUploadedSlides = [];
            } else {
              this.searchResultFoundCount++;
              this.allUploadedSlides = uploadedSlides;
              this.startUploadedIndex = 0;
              console.log(uploadedSlides);
              // this.getSlideDetails('uploaded');
              this.getUploadedSlidesDetailss(this.slidesPageSize);
              this.getUploadedSlidesDetails(this.slidesPageSize);
              this.startUploadedIndex += this.slidesPageSize;
            }
          } else {
            console.log(result);
          }
          this.searchResultCompletedInfo.slides = true;
        })
        .catch((err) => {
          console.log(err);
          this.searchResultCompletedInfo.slides = true;
        });
      this.setIntervalX(
        () => {
          this.cacheKey = +new Date();
        },
        500,
        1,
      );
    },
    getItemsInScreen() {
      if (window.innerWidth <= 786) {
        this.frames = '1fr';
      } else if (window.innerWidth >= 787 && window.innerWidth <= 1200) {
        this.frames = '1fr 1fr';
      } else {
        this.frames = '1fr 1fr 1fr';
      }
    },
    async getSearchTerms() {
      await getUserSearchTerms()
        .then((searchData) => {
          const terms = searchData.data.searchByUser.items;
          if (terms.length) {
            this.searchTerms = terms;
          }
        })
        .catch((err) => console.log(err));
    },
    async logSearch(term) {
      await this.getSearchTerms();
      const index = this.searchTerms.findIndex((item) => {
        const category = item.category.toLowerCase().replace('_', ' ');
        const termName = term.name.toLowerCase().replace('_', ' ');
        return category === termName;
      });
      if (this.searchTerms && index >= 0) {
        this.searchTerms[index].count += 1;
        const searchData = {
          id: this.searchTerms[index].id,
          count: this.searchTerms[index].count,
          searchTermResult: !(term === undefined),
        };
        updateSearchTerms(searchData)
          .then(async (value) => {
            console.log(value);
            await this.getSearchTerms();
          })
          .catch((err) => console.log(err));
      } else {
        const searchData = {
          id: uuidv4(),
          userID: this.currentUser.user.id,
          searchTerm: term.display_name,
          category: term.name,
          count: 1,
          createdAt: new Date().toISOString(),
        };
        addSearchTerms(searchData)
          .then(async (value) => {
            console.log(value);
            this.addSearchTerms(value.data.createSearchTerm);
            await this.getSearchTerms();
          })
          .catch((err) => console.log(err));
      }
    },
    getThemeForBPEContent(type) {
      const isNonBrandedCompany =
        this.currentUser?.company?.isNonBranded || false;
      const userSelectedTheme = this.currentUser?.theme || {};
      let theme = this.currentUser?.company?.defaultTemplate;

      if (isNonBrandedCompany && userSelectedTheme[type]) {
        theme = this.currentUser.theme.code;
      }
      return theme;
    },
    async makeSearchWithSearchQuery(searchQuery) {
      const payload = {};
      const searchValue = searchQuery;
      this.searchProgress = true;
      const help = [];
      const users = [];
      const storylines = [];
      const learn = [];
      const prezentationss = [];
      let bpeDecks = [];
      AnalyticsHandler.performHybridSearch(this.currentUser, searchValue);
      MatomoAnalyticsHandler.searchHybrid(searchValue, this.currentUser);
      const theme = this.getThemeForBPEContent('hasBPGPresentations');
      const searches = [
        {
          collection: 'audiences',
          q: searchValue,
          per_page: 250,
          query_by: 'firstName,lastName,email',
        },
        {
          collection: 'private_audiences',
          q: searchValue,
          per_page: 250,
          query_by: 'firstName,lastName,email',
        },
        {
          collection: 'users',
          q: searchValue,
          per_page: 250,
          query_by: 'firstName,lastName,email',
        },
        {
          collection: 'standard_audiences',
          q: searchValue,
          per_page: 250,
          query_by: 'firstName,lastName,email',
          filter_by: 'hasFingerPrint := 1',
        },
        {
          collection: 'learn',
          q: searchValue,
          per_page: 250,
          query_by: 'title, industry',
        },
        // {
        //   collection: 'help',
        //   q: searchValue,
        //   query_by: 'hm_extn,hm_name,hm_desc,title, industry, summary',
        //   per_page: 250,
        //   filter_by: `language:=[all, ${this.currentUser.user.language}]`,
        // },
        {
          collection: 'help',
          q: searchValue,
          query_by: 'hm_extn,hm_name,hm_desc,title, industry, summary',
          per_page: 250,
          filter_by: `language:=[all, ${this.currentUser.user.language}] && id :!=[${this.notValidId}]`,
        },
        {
          collection: 'prezentations',
          q: searchValue,
          query_by: 'name, type, ownerID, ownerName',
          per_page: 250,
          filter_by: 'isBestPracticeDeck:false',
        },
        // {
        //   q: searchValue,
        //   query_by: 'name, type, description, alias',
        //   collection: 'prezentations',
        //   per_page: '250',
        //   filter_by: `isBestPracticeDeck:true && theme:=${theme}`,
        // },
        {
          q: searchValue,
          query_by:
            'type, description, sectionsName, extension, fullName, userID',
          collection: 'storylines',
          per_page: '250',
          filter_by: `storyType:=[${this.currentUser.user.storyPreference[0]}, all]`,
        },
      ];
      await this.getBestPracticesExamplesDecksSearch(searchValue, this.tsKey)
        .then((res) => {
          bpeDecks = res;
        })
        .catch((err) => {
          console.log(err);
          searches.push({
            q: searchValue,
            query_by: 'name, type, description, alias',
            collection: 'prezentations',
            per_page: '250',
            filter_by: `isBestPracticeDeck:true && theme:=${theme}`,
          });
        });
      const tsClient = await TypeSenseServer.getClient();
      console.log('search started:', new Date().toISOString());
      const { results } = await tsClient.multiSearch.perform({
        searches,
      });
      const currUserId = this.currentUser.user.id;
      searches.forEach(({ collection }, index) => {
        const searchResults = results[index];
        searchResults.hits.forEach((audience) => {
          const { document } = audience;
          const createdByMe = document.ownerID === currUserId;
          switch (collection) {
            case 'users': {
              const fullName =
                document.email === currUserId
                  ? `${document.fullName} (Me)`
                  : document.fullName;
              users.push({
                ...document,
                fullName,
                isPrivate: createdByMe,
              });
              break;
            }
            case 'audiences': {
              users.push({
                ...document,
                isPrivate: createdByMe,
              });
              break;
            }
            case 'private_audiences': {
              users.push({
                ...document,
                isPrivate: createdByMe,
                isGroup: document.privateAudienceType === 'Group',
                fingerprintType: document.subType,
                creator: createdByMe ? null : document.ownerName,
              });
              break;
            }
            case 'standard_audiences': {
              users.push({
                ...document,
                fingerprintType: 'Standard',
                isPrivate: createdByMe,
                creator: 'Prezent',
                profileImage: '/assets/img/standard-audience-dropdown-icon.svg',
              });
              break;
            }
            case 'learn': {
              if (document.stype === 'video')
                learn.push({
                  ...document,
                  ...{
                    decodedTitle: he.decode(document.etags.lm_title),
                  },
                });
              break;
            }
            case 'help': {
              if (document.stype === 'S_WALKTHROUGH') {
                const feature =
                  this.featureEnablementConfig[document.etags.hm_name];
                const eligibleWalkthrough = this.eligibleWalkthroughs.find(
                  (wt) =>
                    wt.uuid.toLowerCase() === document.filename.toLowerCase(),
                );
                if (eligibleWalkthrough && (!feature || this[feature])) {
                  help.push({
                    item: document,
                    name: document.title,
                    type: mapType(document.stype),
                  });
                }
              } else if (
                document.stype === 'S_FAQ' ||
                document.stype === 'S_TUTORIAL'
              ) {
                const feature =
                  this.featureEnablementConfig[document.etags.hm_cat];
                console.log(feature, this[feature]);

                if (!feature || this[feature]) {
                  console.log('feature push faq');
                  help.push({
                    item: document,
                    name: document.title,
                    type: mapType(document.stype),
                  });
                }
              } else {
                help.push({
                  item: document,
                  name: document.title,
                  type: mapType(document.stype),
                });
              }
              break;
            }
            case 'prezentations': {
              if (document.isBestPracticeDeck) {
                bpeDecks.push({ ...document });
              } else {
                prezentationss.push({ ...document });
              }
              break;
            }
            case 'storylines': {
              storylines.push({ ...document });
              break;
            }
            default:
              break;
          }
        });
      });
      payload.users = users;
      payload.learn = learn;
      payload.help = help;
      if (prezentationss && prezentationss.length > 0) {
        this.allPrezentations = [...prezentationss];

        const accessLevelList = getAccessLevelFormatedStrings(this.currentUser);
        this.prezentations = this.allPrezentations.map((prz) => {
          let audienceName = 'Restricted';
          const audience = this.allPeople.find(
            (usr) => usr.email === prz.audienceID,
          );
          if (audience && audience.fullName) {
            audienceName = audience.fullName;
          }

          let permissionLevel = null;

          accessLevelList.forEach((item, index) => {
            prz.acl_list?.forEach((listItem) => {
              console.log(item, listItem, item.indexOf(listItem) !== -1);
              if (item.indexOf(listItem) !== -1 && permissionLevel === null) {
                permissionLevel = index;
              }
            });
          });
          // console.log(accessLevelList, permissionLevel, prz.acl_list);
          return {
            ...prz,
            id: prz.prezentationId,
            audience: audienceName,
            permission_level: permissionLevel,
          };
        });

        this.allPrezentations = [...this.prezentations];
        const prezentationArr = await Promise.all(
          this.allPrezentations?.map(async (ele) => {
            if (ele.prezentationType === 'OP') {
              const prezentation = await getPrezentationByID(
                ele.prezentationId,
              );
              return { ...ele, sections: prezentation.sections };
            }
            return { ...ele };
          }),
        );
        this.setAllPrezentationsForLandingPage([...prezentationArr]);
        if (this.prezentations.length) {
          this.searchResultFoundCount++;
        }
        this.searchResultCompletedInfo.prezentation = true;
      } else {
        this.setAllPrezentationsForLandingPage([]);
        this.allPrezentations = [];
        this.prezentations = [];
      }
      if (bpeDecks && bpeDecks.length > 0) {
        this.allBpeDecks = bpeDecks || [];
        this.bpeDecks = this.allBpeDecks.map((prz) => {
          let audienceName = 'Restricted';
          const audience = this.allPeople.find(
            (usr) => usr.email === prz.audienceID,
          );
          if (audience && audience.fullName) {
            audienceName = audience.fullName;
          }
          return {
            ...prz,
            id: prz.prezentationId,
            audience: audienceName,
            sections: JSON.parse(prz.sections),
          };
        });
        if (this.bpeDecks.length) {
          this.searchResultFoundCount++;
        }
        this.searchResultCompletedInfo['best practice library'] = true;
      } else {
        this.allBpeDecks = [];
        this.bpeDecks = [];
        this.setDecksOnLoad([]);
        this.setDecksLoaded([]);
      }
      if (storylines && storylines.length) {
        this.allFilteredStorylines = [...storylines];
        this.filteredStorylines = this.allFilteredStorylines.slice(
          0,
          this.pageSize,
        );
        this.filteredBuild = this.allFilteredStorylines.slice(0, this.pageSize);
        if (this.filteredStorylines.length) {
          this.searchResultFoundCount++;
        }
        if (this.filteredBuild.length) {
          this.searchResultFoundCount++;
        }
        this.searchResultCompletedInfo.storylines = true;
        this.searchResultCompletedInfo.build = true;
      } else {
        this.allFilteredStorylines = [];
        this.filteredStorylines = [];
        this.filteredBuild = [];
      }
      // await API.graphql(
      //   graphqlOperation(performHybridSearch, {
      //     parameters: serialized,
      //     sessionkey: this.sessionKey,
      //   }),
      // )
      //   .then((res) => {
      //     const result = JSON.parse(res.data.performHybridSearch);

      //     if (
      //       result.statusCode === 200 &&
      //       result.body.payloads &&
      //       result.body.payloads.length > 0
      //     ) {
      //       const cat = [];
      //       const searchTerm = searchValue;
      //       result.body.payloads.forEach(async (item) => {
      //         if (item.keytype === 'K_CATEGORY') {
      //           const name = item.name.toLowerCase().replace('_', ' ');
      //           const termToSearch = searchTerm.toLowerCase().replace('_', ' ');
      //           if (name === termToSearch) {
      //             await this.logSearch(item);
      //           }
      //           cat.push(item.name);
      //         }
      //       });
      //       if (cat.length) {
      //         MatomoAnalyticsHandler.searchHybridResultsIdeas(
      //           cat,
      //           this.currentUser,
      //         );
      //       }
      //       if (users.length) {
      //         MatomoAnalyticsHandler.searchHybridResultsPeople(
      //           users,
      //           this.currentUser,
      //         );
      //       }
      //       payload.username = this.currentUser.user.cognitoID;
      //       payload.query = searchValue;
      //       payload.morprefs = cat ? { category: cat } : [];
      //       payload.searchTermTranslated = result.body.translated_query;

      //       payload.limit = 96;
      //       payload.skip = 0;
      //     } else {
      //       payload.username = this.currentUser.user.cognitoID;
      //       payload.category = [];
      //       payload.query = searchValue;
      //       payload.searchTermTranslated = result.body.translated_query;

      //       payload.learn = learn;
      //       payload.help = help;
      //       payload.limit = 96;
      //       payload.skip = 0;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     payload.username = this.currentUser.user.cognitoID;
      //     payload.category = [];
      //     payload.query = searchValue;
      //     payload.searchTermTranslated = '';

      //     payload.learn = learn;
      //     payload.help = help;
      //     payload.limit = 96;
      //     payload.skip = 0;
      //   });
      this.searchProgress = false;
      return payload;
    },
    async handleLoadMoreBPEDecks() {
      const searchText = this.$route.query.s;
      let bpeDecks = [];
      await this.getBestPracticesExamplesDecksSearch(
        searchText,
        this.tsKey,
      ).then((res) => {
        bpeDecks = res;
      });
      if (bpeDecks && bpeDecks.length > 0) {
        this.allBpeDecks = [...this.allBpeDecks, ...bpeDecks];
        this.bpeDecks = this.allBpeDecks.map((prz) => {
          let audienceName = 'Restricted';
          const audience = this.allPeople.find(
            (usr) => usr.email === prz.audienceID,
          );
          if (audience && audience.fullName) {
            audienceName = audience.fullName;
          }
          return {
            ...prz,
            id: prz.prezentationId,
            audience: audienceName,
            sections: JSON.parse(prz.sections),
          };
        });
        this.setDecksLoaded(this.bpeDecks);
        if (this.bpeDecks.length) {
          this.searchResultFoundCount++;
        }
        this.searchResultCompletedInfo['best practice library'] = true;
      }
    },
    async getBestPracticesExamplesDecksSearch(query, tsKey) {
      const theme = this.getThemeForBPEContent('hasBPGPresentations');
      const searchParameters = {
        query: query || '*',
        filters: {
          and: {
            theme,
            isBestPracticeDeck: 'true',
          },
          or: {},
        },
        page: this.bpeDecksPage,
        limit: 6,
      };
      if (this.bpeDecksPage < 40) {
        return getPrezentationSearch(searchParameters, tsKey).then(
          (response) => {
            this.totalDecks = response?.data?.result?.length;
            if (response?.data?.result?.length) {
              if (response?.data?.result?.length < 6) {
                this.setIsLoadMore(false);
              }
              this.bpeDecksPage++;
              return response.data.result.map((tkt) => ({
                ...tkt?.prezentation,
              }));
            }
            this.setIsLoadMore(false);
            return [];
          },
        );
      }

      this.setIsLoadMore(false);
      return [];
    },
    makeTemplateSearchOnMount(payload) {
      this.searchTermToLog = payload;
      this.doTemplateSearch(payload);
    },
    async makeKnowSearchOnMount(payload) {
      this.searchTermToLog = payload.query;
      this.searchTermTranslatedToLog = payload.searchTermTranslated;
      await this.doKnowSearch(payload);
    },
    async makeOpSearchOnMount(payload) {
      this.searchTermToLog = payload;
      await this.doOPSearch(payload);
    },
    updatePeopleOnceAudiencesLoaded() {
      let loadedPeople = [...this.allPeople];

      const audienceList = [...this.allPeople];

      audienceList.forEach((audience) => {
        this.audienceList.push({ ...audience, active: true });
      });

      // if private audience then append (Private) to the name
      // for private aud, display_name has "(Private)" as suffix
      // and phaedrus username = "<Priv aud ddb id>_<Priv aud ddb userid>"
      loadedPeople.forEach((user) => {
        let audience = null;
        if (user.display_name?.split(' ').slice(-1)[0] === '(Private)') {
          audience = this.typesenseRealtimeAudiences.find(
            (a) =>
              a.email.concat('_', a.userID).toLowerCase() ===
                user.name.toLowerCase() && a.fingerPrint,
          );
          if (audience) {
            audience.lastName = replacePrivateInParenthesis(audience.lastName);
            audience.isPrivateAudience = true;
            audience = formatUserForPrivateAudienceItem(
              audience,
              this.currentUser,
            );
          }
        } else {
          audience = this.audienceList.find(
            (a) =>
              a.id.toLowerCase().includes(user.name?.toLowerCase()) ||
              (a.cognitoID && a.cognitoID === user.name),
          );
          if (!audience) {
            audience = [...this.typesenseRealtimeAudiences].find(
              (sUser) => sUser.id === user.id,
            );
            if (audience) {
              audience.isShared = true;
              audience.active = true;
              audience = {
                ...audience,
              };
            }
          }
        }
        if (audience) {
          if (getIsGroup(audience)) {
            audience.lastName = '';
          }
          loadedPeople = [...loadedPeople, audience];
        }
      });

      this.allPeople = [...loadedPeople];
    },
    // async makeSlidesSearchOnMount(payload) {
    //   // keywords for which results if present will be available
    //   // at this moment and are not controlled by feature flag
    //   // - people, learn, help and prezentations
    //   // this.searchResultCompletedInfo.prezentation = true;
    // },
    removeSlideOnDelete(slide, origin) {
      if (origin === 'uploaded') {
        const posToDelete = this.uploadedSlides.findIndex(
          (item) => item.unique_id === slide.unique_id,
        );
        if (posToDelete > -1) {
          this.uploadedSlides.splice(posToDelete, 1);
        }

        // deleting from allSlides state property
        const posToDeleteAll = this.allUploadedSlides.findIndex(
          (item) => item.unique_id === slide.unique_id,
        );
        if (posToDeleteAll > -1) {
          this.allUploadedSlides.splice(posToDeleteAll, 1);
        }
      } else {
        const posToDelete = this.slides.findIndex(
          (item) => item.unique_id === slide.unique_id,
        );
        this.slides.splice(posToDelete, 1);

        // deleting from allSlides state property
        const posToDeleteAll = this.allSlides.findIndex(
          (item) => item.unique_id === slide.unique_id,
        );
        if (posToDeleteAll > -1) {
          this.allSlides.splice(posToDeleteAll, 1);
        }
      }
    },

    // function to fetch all user collections
    async getCollectionData() {
      await getCollection()
        .then(async (resp) => {
          this.setCollectionData(resp.data.collections);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async beforeMount() {
    if (this.isKnowEnabled) {
      this.keywords.splice(3, 0, {
        name: 'Best Practice Library',
        title: 'prezentationGallery.bestPracticeGuides',
        category: 'Best practice library',
      });
    }
    if (this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access') {
      this.keywords.splice(8, 0, {
        name: 'Overnight Requests',
        title: 'overnightPresentations.overnightRequests',
        category: 'Overnight Requests',
      });
    }
    await this.fetchTrendingSlides();
  },
  async mounted() {
    this.loading = true;
    this.setAllPrezentationsForLandingPage([]);
    this.eligibleWalkthroughs = await this.getWalkthroughs();
    this.getOnboardingTutorialDetails();
    this.tsKey = await getTypeSenseAPIKey();
    this.setIsLoadMore(true);
    if (
      (this.prezentationBackClicked || this.slideBackClicked) &&
      this.snapshot.Search
    ) {
      Object.assign(this.$data, this.snapshot.Search);
      const prezentationArr = await Promise.all(
        this.allPrezentations?.map(async (ele) => {
          if (ele.prezentationType === 'OP') {
            const prezentation = await getPrezentationByID(ele.prezentationId);
            return { ...ele, sections: prezentation.sections };
          }
          return { ...ele };
        }),
      );
      this.setAllPrezentationsForLandingPage([...prezentationArr]);
      this.setSlideBackClicked(false);
      setTimeout(() => {
        window.scrollTo({
          top: this.offsetTop,
          behaviour: 'smooth',
        });
      }, 200);
      return;
    }

    this.searchProgress = true;
    this.initialize();
    EventBus.$on('CLEAR_SEARCH_COUNT', () => {
      this.searchResultFoundCount = 0;
      this.searchLogged = false;
      this.searchResultCompletedInfo = {
        people: false,
        slides: false,
        learn: false,
        prezentation: false,
        'best practice library': false,
        template: false,
        storylines: false,
        help: false,
      };
    });
    const searchText = this.$route.query.s;
    this.searchTermToLog = searchText;
    const slidePayload = {
      query: searchText,
    };
    this.searchKnowSlides = true;

    // getting user slide collections in hybrid search page
    await this.getCollectionData();

    this.doSlideSearch(slidePayload);
    this.doUploadedSlideSearch(slidePayload);
    // await this.makeSlidesSearchOnMount(slidePayload); // Has values assignment for slides, learn, help, people
    this.makeKnowSearchOnMount(slidePayload);

    const payload = await this.makeSearchWithSearchQuery(searchText);

    this.searchTermTranslatedToLog = payload.searchTermTranslated;
    this.allLearn = payload.learn ? payload.learn : [];
    this.learn = this.allLearn.slice(0, 4);
    this.allHelp = payload.help ? payload.help : [];
    this.help = this.allHelp.slice(0, 8);
    const { users } = payload;
    this.allPeople.splice(0, this.allPeople.length);
    if (users && users.length > 0) {
      users.forEach((user) => {
        if (user) {
          this.allPeople.push(user);
        }
      });
    }

    this.savedQueryPayload = {
      ...payload,
      selectedChip: this.selectedChip,
    };
    this.setSavedQueryPayload(this.savedQueryPayload);
    this.people = this.allPeople.slice(0, this.audiencePageSize);
    if (
      this.people.length ||
      this.learn.length ||
      this.help.length
      // this.prezentations.length
    ) {
      this.searchResultFoundCount++;
    }
    this.searchResultCompletedInfo.people = true;
    this.searchResultCompletedInfo.learn = true;
    this.searchResultCompletedInfo.help = true;

    await this.makeOpSearchOnMount(searchText);
    this.makeTemplateSearchOnMount(searchText);
    // this.makeStorylinesSearchOnMount(searchText);
    // this.getPrezentationsForHybridSearch();
    // this.getAllBPEDecksForHybridSearch();
    if (this.startLoadingSlidesFlag) {
      this.updatePeopleOnceAudiencesLoaded();
    }
    if (this.$route.query) {
      this.selectedChip = this.$route.query.chip?.toLowerCase();
    }
    this.savedQueryPayload = {
      ...this.savedQueryPayload,
      selectedChip: this.selectedChip,
    };
    this.setSavedQueryPayload(this.savedQueryPayload);
    this.loading = false;
    this.searchProgress = false;
    this.listDownloads = [];
    window.addEventListener('resize', this.getItemsInScreen);
  },
  destroyed() {
    window.removeEventListener('resize', this.getItemsInScreen);
  },
  beforeDestroy() {
    // EventBus.$off('DO_TEMPLATESEARCH');
    // EventBus.$off('DO_STORYLINES_SEARCH');
    // EventBus.$off('DO_KNOWSEARCH');
    // EventBus.$off('DO_SLIDESEARCH');
    // EventBus.$off('DO_OPSEARCH');
  },
  watch: {
    searchResultFoundCount(value) {
      if (value && this.searchLogged === false) {
        this.searchLogged = true;
        const searchTerm = this.searchTermToLog
          ? this.searchTermToLog.toLowerCase()
          : '';
        const logPayload = {
          searchTermResult: true,
          type: 'Hybrid',
          searchTerm,
          searchTermTranslated: this.searchTermTranslatedToLog,
        };
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(`logSearchQuery error - ${err}`));
      }
    },
    getAllPrezentations: {
      handler(val) {
        this.allPrezentations = val;
      },
      deep: true,
      immediate: true,
    },
    getFilteredAndSearchedPrezentations: {
      handler(val) {
        this.allPrezentations = val;
      },
      deep: true,
      immediate: true,
    },
    slides() {
      this.setSlidesLoadedInUI(this.slides);
    },
    searchResultCompletedInfo: {
      handler(newValue) {
        // using deep watch to log no result found
        // when searching for all enabled keywords completed
        if (
          this.searchResultFoundCount === 0 &&
          newValue.people &&
          newValue.slides &&
          newValue.learn &&
          newValue.prezentation &&
          newValue.template &&
          newValue.help &&
          (!this.isKnowEnabled || newValue['best practice library'])
        ) {
          this.searchLogged = true;
          const searchTerm = this.searchTermToLog
            ? this.searchTermToLog.toLowerCase()
            : '';
          const logPayload = {
            searchTermResult: false,
            type: 'Hybrid',
            searchTerm,
            searchTermTranslated: this.searchTermTranslatedToLog,
          };
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(`logSearchQuery error - ${err}`));
        }
      },
      deep: true,
    },
    '$route.query.s': {
      async handler(newText, oldText) {
        if (oldText && oldText !== '') {
          if (newText && newText !== oldText) {
            this.loading = true;
            const searchText = this.$route.query.s;
            this.searchTermToLog = searchText;
            const slidePayload = {
              query: searchText,
            };
            this.initialize();
            this.searchProgress = true;
            this.searchKnowSlides = true;

            // getting user slide collections in hybrid search page
            await this.getCollectionData();

            await this.doSlideSearch(slidePayload);
            this.doUploadedSlideSearch(slidePayload);
            // this.makeSlidesSearchOnMount(slidePayload); // Has values assignment for slides, learn, help, people
            this.makeKnowSearchOnMount(slidePayload);
            const payload = await this.makeSearchWithSearchQuery(searchText);

            this.searchTermTranslatedToLog = payload.searchTermTranslated;
            this.allLearn = payload.learn ? payload.learn : [];
            this.learn = this.allLearn.slice(0, 4);
            this.allHelp = payload.help ? payload.help : [];
            this.help = this.allHelp.slice(0, 8);
            const { users } = payload;
            this.allPeople.splice(0, this.allPeople.length);
            if (users && users.length > 0) {
              users.forEach((user) => {
                if (user) {
                  this.allPeople.push(user);
                }
              });
            }

            this.savedQueryPayload = {
              ...payload,
              selectedChip: this.selectedChip,
            };
            this.setSavedQueryPayload(this.savedQueryPayload);
            this.people = this.allPeople.slice(0, this.audiencePageSize);
            if (
              this.people.length ||
              this.learn.length ||
              this.help.length
              // this.prezentations.length
            ) {
              this.searchResultFoundCount++;
            }
            this.searchResultCompletedInfo.people = true;
            this.searchResultCompletedInfo.learn = true;
            this.searchResultCompletedInfo.help = true;

            EventBus.$on('CLEAR_SEARCH_COUNT', () => {
              this.searchResultFoundCount = 0;
              this.searchLogged = false;
              this.searchResultCompletedInfo = {
                people: false,
                slides: false,
                learn: false,
                prezentation: false,
                'best practice library': false,
                template: false,
                storylines: false,
                help: false,
              };
            });
            await this.makeOpSearchOnMount(searchText);
            this.makeTemplateSearchOnMount(searchText);
            // this.makeStorylinesSearchOnMount(searchText);
            // this.getPrezentationsForHybridSearch();
            // this.getAllBPEDecksForHybridSearch();
            if (this.startLoadingSlidesFlag) {
              this.updatePeopleOnceAudiencesLoaded();
            }
            this.loading = false;
            this.searchProgress = false;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    startLoadingSlidesFlag(val) {
      if (val === true) {
        this.updatePeopleOnceAudiencesLoaded();
      }
    },
    themes(val) {
      if (val) {
        // const filteredArray = val.filter((theme) =>
        //   this.isSubArray(theme, this.$route.query.s),
        // );
        const filteredArray = val.filter((theme) =>
          theme.name
            .toLowerCase()
            .includes(this.$route?.query?.s?.toLowerCase()),
        );
        this.allTemplatesFiltered = filteredArray;
        this.templatesFiltered = this.allTemplatesFiltered.slice(
          0,
          this.pageSize,
        );
        if (this.templatesFiltered.length) {
          this.searchResultFoundCount++;
        }
        this.searchResultCompletedInfo.template = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.prezentations-wrapper {
  width: 100%;
  .prezentations-wrapper-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 30px;
  }
  .prezentations-content-wrapper {
    width: 100%;
    padding-right: 0px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: scrollbartrack-vertical;
    }
    /* .slide-wrapper:nth-child(odd) {
      .slider-content-wrapper {
        margin-left: 12.5px;
        margin-right: 12.5px;
      }
    }
    .slide-wrapper:nth-child(even) {
      .slider-content-wrapper {
        margin-left: 12.5px;
        margin-right: 12.5px;
      }
    } */
    .row {
      margin-right: -30px !important;
    }
    .prezentation-wrapper {
      margin-bottom: 40px;
      min-width: 300px;
      .prezentation-content-wrapper {
        margin-bottom: 5px;
        box-shadow: -2px 2px 25px 1px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        position: relative;
        .already-download {
          position: absolute;
          top: 12px;
          right: 12px;
        }
      }
      .prezentation-content-wrapper:hover {
        box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
      }
      .image-wrapper img {
        border-radius: 8px;
        padding-top: 5%;
        max-height: 90%;
        max-width: 90%;
        cursor: pointer;
      }
      .image-wrapper {
        border-bottom: 1px solid rgba(211, 211, 211, 0.2);
        cursor: pointer;
        text-align: center;
      }
      .bottom-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        .bottom-wrapper-title {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }
        .action-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          .favorite {
            cursor: pointer;
          }
          .show-detail {
            cursor: pointer;
            margin-right: -1px;
            margin-left: 22px;
            cursor: pointer;
          }
          ::v-deep .fab-btn {
            box-shadow: none;
            background: white;
            padding: 0;
            &::before {
              display: none;
            }
          }
          ::v-deep .new-slide-speed .v-speed-dial__list {
            left: -60px;
          }
        }
        .created-time {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #68788c;
        }
      }
    }
  }
}
.template-wrapper {
  display: block;
  width: 100%;
  max-width: 90.4vw;
  padding-bottom: 20px;
  .template-wrapper-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 30px;
  }
}
.available-template-list {
  align-items: top;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .available-template {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5px;
    max-width: 320px;
    width: 100%;
    .available-template-img {
      width: fit-content;
    }
    img {
      height: 171px;
      width: 308px;
    }
    .available-template-wrapper {
      border-radius: 8px;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);
      margin: auto;
      margin-bottom: 35px;
      overflow: hidden;
      width: fit-content;
      &.add-new {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 226px;
        justify-content: center;
        width: 308px;
        .icon-wrapper {
          transform: scale(3);
        }
        .add-new-title {
          color: $zen-blue-default;
          font-size: 20px;
          margin-top: 20px;
        }
      }
    }
    .v-card__title {
      display: block;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 18px 15px;
      text-align: left;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      width: 16rem !important;
      max-width: unset !important;
    }
    .select-template {
      color: white;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: capitalize;
    }
    .select-template.active {
      color: $zen-blue-default;
      width: auto;
    }
  }
}
.see-more-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.help-wrapper {
  width: 100%;
  max-width: 90.4vw;
  padding-bottom: 25px;
  .help-action {
    display: flex;
    margin-top: 30px;
    border-bottom: 1px solid $light-gray;
    justify-content: center;
    margin-bottom: 37px;
    padding-bottom: 24px;
    button {
      color: $zen-blue-default;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      text-transform: none;
    }
  }
  .help-wrapper-title {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 30px;
    text-align: left;
  }
  .help-wrapper-content {
    padding: 0 1rem;
  }
  .help-wrapper-item {
    cursor: pointer;
    display: flex;
  }
  .help-wrapper-item-icon {
    padding: 0.5rem;
    margin-right: 0.25rem;
    .inner {
      align-items: center;
      background: white;
      border-radius: 0.5rem;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      height: 64px;
      width: 64px;
      img {
        opacity: 0.5;
        transform: scale(1.2);
      }
    }
  }
  .help-wrapper-item-content {
    align-items: center;
    display: flex;
    padding-top: 1rem;
    .help-wrapper-item-name {
      font-family: 'Lato';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
    .help-wrapper-item-type {
      /* identical to box height */
      color: #68788c;
      font-family: 'Lato';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.01em;
      line-height: 17px;
    }
  }
}

.search-wrapper {
  width: 100%;
  height: 100%;
  padding-left: 24px;
  display: grid;
}

.slide-type-wrapper {
  display: flex;
  padding: 37px 0 20px 20px;
  width: 100%;

  .slide-type-wrapper-title {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-right: 8px;
  }
  ::v-deep .v-chip {
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    margin-right: 18px !important;
    padding: 0 25px !important;

    &.v-chip--active.v-chip--active {
      background: $zen-blue-default !important;
      color: white !important;
    }
  }
}
.top-wrapper {
  display: flex;
  margin-right: 5px;
  justify-content: space-between;
  .slides-landing-btns {
    display: flex;
    gap: 10px;
  }
}
.people-wrapper-small {
  width: 93.5% !important;
  max-width: 90.4vw !important;
}
.prezentation-wrapper-small {
  width: 93.5% !important;
  max-width: 80vw !important;
}
.loadmore-btn {
  margin-top: 22px !important;
  margin-bottom: 0px !important;
}
.people-wrapper {
  width: 100%;
  max-width: 90.4vw;
  .people-wrapper-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 30px;
  }
  .audience-action {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 4px;

    button {
      color: $zen-blue-default;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      text-transform: none;
    }
  }
  .people-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 25px;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 20px;

    .people-item {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 29px;

      ::v-deep .audience-item {
        max-width: 90%;
        .profile-avatar {
          &:hover {
            box-shadow: -4px 4px 25px 1px rgb(0 0 0 / 30%);
          }
          .profile-avatar--initial {
            font-size: 22px;
          }
        }
      }
      .people-item-img-wrapper {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 9px;
      }

      .people-item-info {
        .people-item-info-name {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 4px;
        }

        .people-item-info-desc {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #68788c;
        }
      }
    }
  }
}

.slides-result-wrapper,
.prezentations-result-wrapper,
.border-wrapper,
.know-result-wrapper {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 20px;
  max-width: 90.4vw;
}

.prezentations-result-wrapper,
.know-result-wrapper {
  padding-bottom: 28px;
}

.slides-wrapper {
  display: block;
  width: 100%;
  .slides-wrapper-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 30px;
  }
  .slides-wrapper-subtitle {
    font-size: 22px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 30px;
  }

  .slides-action {
    border-bottom: 1px solid $light-gray;
    margin-bottom: 37px;
    padding-bottom: 24px;

    button {
      color: $zen-blue-default;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      text-transform: none;
    }
  }

  .slides-content-wrapper {
    width: 100%;
    padding-right: 0px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: scrollbartrack-vertical;
    }
    /* .slide-wrapper:nth-child(odd) {
      .slider-content-wrapper {
        margin-left: 12.5px;
        margin-right: 12.5px;
      }
    }
    .slide-wrapper:nth-child(even) {
      .slider-content-wrapper {
        margin-left: 12.5px;
        margin-right: 12.5px;
      }
    } */
    .row {
      margin-right: -30px !important;
    }

    .slide-wrapper {
      margin-bottom: 40px;
      text-align: center;
      .slider-content-wrapper {
        margin-bottom: 5px;
        box-shadow: -2px 2px 25px 1px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        position: relative;

        .already-download {
          position: absolute;
          top: 12px;
          right: 12px;
        }
      }
      .slider-content-wrapper:hover {
        box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
      }
      .image-wrapper img {
        border-radius: 8px;
        padding-top: 5%;
        max-height: 90%;
        max-width: 90%;

        cursor: pointer;
      }
      .image-wrapper {
        border-bottom: 1px solid rgba(211, 211, 211, 0.2);
        cursor: pointer;
      }
      .bottom-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        .bottom-wrapper-title {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
        .action-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          .favorite {
            cursor: pointer;
          }
          .show-detail {
            cursor: pointer;
            margin-right: -1px;
            margin-left: 22px;
            cursor: pointer;
          }

          ::v-deep .fab-btn {
            box-shadow: none;
            background: white;
            padding: 0;

            &::before {
              display: none;
            }
          }

          ::v-deep .new-slide-speed .v-speed-dial__list {
            left: -60px;
          }
        }
        .trending {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img {
            margin-right: 3px;
          }
          .trending-title {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #68788c;
          }
        }
      }
    }
  }
}

.learn-wrapper {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #dfdfdf;
  max-width: 90.4vw;

  .learn-wrapper-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 27px;
  }

  .learn-action {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;

    button {
      color: $zen-blue-default;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      text-transform: none;
    }
  }

  .learn-wrapper-items {
    margin: 0 19px;
    margin-bottom: 55px;
    display: flex;
    flex-direction: row;

    .learn-wrapper-item {
      cursor: pointer;
      flex: 50%;
      flex-direction: row;
      display: flex;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      width: 100%;
      padding: 2% 0%;
      // box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.5), 0 1px 1px 0 #68788c,
      //   0 1px 8px 0 rgba(0, 0, 0, 0.1);

      .learn-wrapper-item-hover {
        display: none;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.9);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 25px 19px;

        .learn-wrapper-item-hover-title {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          margin-bottom: 8px;
        }
        .learn-wrapper-item-hover-desc {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }
      }

      &:hover {
        .learn-wrapper-item-hover {
          display: block;
        }
      }

      .learn-wrapper-item-info-wrapper {
        background: white;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 5px;

        .learn-wrapper-item-info-title {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          // padding: 2% 0%;
          width: 100%;
        }

        .learn-wrapper-item-info-desc {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #68788c;
          width: 100%;
        }
      }
    }
  }
}

.storylinesWrapper {
  width: 100%;
  max-width: 90.4vw;
  .storylinesTitle {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 30px;
  }
  .storylinesList {
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    .storylinesListItem {
      display: flex;
      .storylineIconContainer {
        &:hover {
          cursor: pointer;
        }
      }
      .storyLineInfo {
        padding: 0px 0px 0px 16px;
        margin: auto 0;
        .name {
          font-weight: 600;
          font-size: 16px;
        }
        .description,
        .author,
        .createdDate {
          color: #68798d;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .loadMoreStroylinesBtn {
    margin: 25px 0px 28px 0px;
    text-align: center;
    .v-btn {
      min-width: 157px;
      // letter-spacing: normal !important;
    }
  }
}
::v-deep .v-slide-group__prev--disabled,
::v-deep .v-slide-group__next--disabled {
  display: flex !important;
}
.bottom-wrapper-title-header {
  padding: 0;
  font-family: 'Lato' !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.practice-wrapper {
  margin-bottom: 53px;
  border-bottom: 1px solid #dfdfdf;

  .practice-wrapper-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 27px;
  }

  .practice-wrapper-items {
    margin: 0 19px;
    margin-bottom: 55px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .practice-wrapper-item {
      border-radius: 8px;
      overflow: hidden;
      margin-right: 86px;
      width: 220px;
      position: relative;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.5), 0 1px 1px 0 #68788c,
        0 1px 8px 0 rgba(0, 0, 0, 0.1);

      .practice-wrapper-item-info-wrapper {
        background: white;
        padding: 10px 11px;

        .practice-wrapper-item-info-title {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }

        .practice-wrapper-item-info-sub-title {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }
      }
    }
  }
}

.not-found-container {
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.not-found-descriptions {
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  width: fit-content;

  .final-not-found-description {
    margin-bottom: 24px;
  }

  button {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    text-transform: none;
  }
}
.bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  .bottom-wrapper-title {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .favorite {
      cursor: pointer;
    }
    .show-detail {
      cursor: pointer;
      margin-right: -1px;
      margin-left: 22px;
      cursor: pointer;
    }
    ::v-deep .fab-btn {
      box-shadow: none;
      background: white;
      padding: 0;
      &::before {
        display: none;
      }
    }
    ::v-deep .new-slide-speed .v-speed-dial__list {
      left: -60px;
    }
  }
  .created-time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #68788c;
  }
}

::v-deep {
  .v-card__title {
    display: block;
    max-width: 9rem;
    text-align: left;
  }
}
</style>
