<template>
  <div class="root">
    <div v-if="searchProgress" class="spinner-wrapper">
      <LoadSpinner
        :id="'spinner'"
        :text="'Loading...'"
        :size="'60'"
        :bgcolor="'lightgray'"
        :speed="'0'"
        :color="'gray'"
        class="spinner"
      />
    </div>
    <div class="slide-type-wrapper" v-if="!noResults">
      <v-sheet class="chipgroup ml-n4 pl-n1" max-width="92vw">
        <v-chip-group show-arrows mandatory active-class="primary--text">
          <v-chip
            v-for="(keyword, index) in keywords"
            :key="index"
            @click="(e) => filterClick(e, keyword)"
          >
            {{ keyword }}
          </v-chip>
        </v-chip-group>
      </v-sheet>
    </div>
    <template>
      <div class="not-found-container" v-if="noResults && !searchProgress">
        <div>
          <img src="/assets/img/fingerprints-not-found.png" alt />
        </div>

        <div class="not-found-descriptions">
          <div>{{ $t('bestPracticesGuide.knowContainer.noResultsFound') }}</div>
          <div class="not-found-border">
            {{ $t('search.dontLoseHeart') }}
          </div>
          <!-- <div
                class="final-not-found-description"
              >Couldn’t find the colleague you were looking for?</div>
              <v-btn
                class="create-new ma-2 py-2"
                rounded
                color="#21a7e0"
                width="292"
                height="50"
                @click="handleAddNew"
          >Add New Colleague</v-btn>-->
        </div>
      </div>
    </template>
    <div
      v-if="
        users.length > 0 &&
        (selectedFilter === 'All' || selectedFilter === 'People')
      "
    >
      <h2 class="mx-11 my-3 d-flex">
        {{ $t('fingerprint.searchPage.people') }}
      </h2>
      <div class="d-flex flex-wrap ml-8">
        <div v-for="(item, index) in users" :key="index" class="col-sm-4 pa-2">
          <div class="d-flex" @click="handleClickDetail(item, 'User')">
            <v-avatar size="68">
              <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
            </v-avatar>
            <div class="pl-2 pt-2">
              <strong class="user-name">{{ item.display_name }}</strong>
              <br />
              <span class="user-designation">{{
                $t('search.someDesignation')
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="divider mt-6 ml-10 mr-10" />
    </div>
    <div
      v-if="
        slides.length > 0 &&
        (selectedFilter === 'All' || selectedFilter === 'Slides')
      "
    >
      <h2 class="mx-11 my-3 d-flex">Slides</h2>
      <div class="d-flex flex-wrap ml-8">
        <div
          class="slide-wrapper col-sm-3"
          v-for="(item, index) in slides"
          :key="index"
        >
          <div class="slider-content-wrapper">
            <div
              :key="cacheKey"
              class="image-wrapper"
              @click="handleClickDetail(item, 'Slide')"
            >
              <img src="/assets/img/404.png" alt height="204px" />
              <!-- <img :src="item.thumbnail" alt height="204px" /> -->
            </div>
            <div class="bottom-wrapper">
              <div class="bottom-wrapper-title">
                <div class="bottom-wrapper-title-header">
                  {{ item.display_name }}
                  <!-- {{ item.asset.name.substring(0, item.asset.name.indexOf("_")) }} -->
                </div>
                <div :class="`trending ${item.isTrending ? 'show' : 'hide'}`">
                  <img src="/assets/img/trending.svg" alt />
                  <div class="trending-title">
                    {{ $t('bestPracticesGuide.knowSlideCard.trending') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider mt-6 ml-10 mr-10" />
    </div>
    <div
      v-if="
        learn.length > 0 &&
        (selectedFilter === 'All' || selectedFilter === 'Learn')
      "
    >
      <h2 class="mx-11 my-3 d-flex">{{ $t('profile.learn') }}</h2>
      <div class="d-flex flex-wrap ml-8">
        <div
          class="slide-wrapper col-sm-3"
          v-for="(item, index) in learn"
          :key="index"
        >
          <div
            class="slider-content-wrapper"
            @click="handleClickDetail(item, 'Learn')"
          >
            <div :key="cacheKey" class="image-wrapper">
              <img src="/assets/img/404.png" alt height="204px" />
              <!-- <img :src="item.thumbnail" alt height="204px" /> -->
            </div>
            <div class="bottom-wrapper">
              <div class="bottom-wrapper-title">
                <div class="bottom-wrapper-title-header">
                  {{ item.title.substr(0, item.title.indexOf(':')) }}
                  <!-- {{ item.asset.name.substring(0, item.asset.name.indexOf("_")) }} -->
                </div>
              </div>
              <!-- <div class="action-wrapper">
                <div class="percentage">0%</div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="divider mt-6 ml-10 mr-10" />
    </div>
    <div
      v-if="
        slides.length > 0 &&
        (selectedFilter === 'All' || selectedFilter === 'My Prezentations')
      "
    >
      <h2 class="mx-11 my-3 d-flex">{{ $t('search.myPrezentations') }}</h2>
      <div class="d-flex flex-wrap ml-8">
        <div
          class="slide-wrapper col-sm-3"
          v-for="(item, index) in slides"
          :key="index"
        >
          <div class="slider-content-wrapper">
            <div
              :key="cacheKey"
              class="image-wrapper"
              @click="handleClickDetail(item)"
            >
              <img src="/assets/img/404.png" alt height="204px" />
              <!-- <img :src="item.thumbnail" alt height="204px" /> -->
            </div>
            <div class="bottom-wrapper">
              <div class="bottom-wrapper-title">
                <div class="bottom-wrapper-title-header">
                  test
                  <!-- {{ item.asset.name.substring(0, item.asset.name.indexOf("_")) }} -->
                </div>
                <div :class="`trending `">
                  <div class="trending-title">Created on 02/12/2021</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider mt-6 ml-10 mr-10" />
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import { mapState } from 'vuex';
import EventBus from '../../common/event-bus';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import { performHybridSearch } from '../../../graphql/queries';
import LoadSpinner from '../../common/LoadSpinner.vue';

export default {
  name: 'SearchResults',
  components: {
    LoadSpinner,
  },
  data() {
    return {
      searchProgress: true,
      searchResults: [],
      users: [],
      slides: [],
      keywords: ['All', 'People', 'Slides', 'Learn', 'My Prezentations'],
      selectedFilter: 'All',
      learn: [],
      noResults: true,
    };
  },
  computed: {
    ...mapState('users', ['sessionKey']),
    ...mapState('users', ['currentUser']),
    // ...mapState("metrics", ["currentUserLimit", "organizationLimit"]),
    // ...mapState("learndata", ["organizationLearnIndex"]),
  },
  methods: {
    // ...mapActions("users", ["setCurrentUser"]),
    // showSearchResults(payload){
    // }
    filterClick(e, keyword) {
      this.selectedFilter = keyword;
    },
    handleClickDetail(item, key) {
      if (key === 'User') {
        const username = item.display_name.substr(
          0,
          item.display_name.indexOf(' '),
        );
        this.$router.push(`/home/fingerprints?username=${username}`);
      } else if (key === 'Slide') {
        this.search_query = this.item.category;
        if (!this.search_query) {
          this.search_query = this.item.name;
        }
        this.doSearch();
      } else if (key === 'Learn') {
        const payload = {};
        payload.level = item.level;
        payload.module = item.module;

        if (!this.currentRoute().includes('choose')) {
          this.$router.push(
            `/home/learn/choose?module=${item.module.toString()}&level=${item.level.toString()}`,
          );
        }
        EventBus.$emit('DO_LEARN', payload);
      }
    },
    init(searchValue) {
      // this.searchResults = payload;
      // payload?.forEach((item) => {
      //   if (item.keytype === "K_USERDATA") {
      //     //users
      //     this.users.push({
      //       name: item.display_name,
      //     });
      //   } else if (item.keytype === "K_CATEGORY") {
      //     //slides
      //     this.slides.push({
      //       name: item.display_name,
      //       category: item.name,
      //     });
      //   } else if (item.keytype === "K_LEARNDATA") {
      //     //learn
      //     this.learn.push({
      //       item: item,
      //       name: item.title,
      //       level: this.getLearnLevel(item.unique_id),
      //       module: this.getLearnModule(item.unique_id),
      //     });
      //   }
      // });
      console.log(this.searchProgress);
      const params = {
        username: 'pz_rath_admin',
        query: searchValue,
        orderby: 'CHOICE_DESC',
        choice: { visual: 6.0, data: 5.0 },
        company: this.currentUser.company.id,
        limit: 10,
        skip: 0,
      };
      const serialized = JSON.stringify(params);
      AnalyticsHandler.performHybridSearch(this.currentUser, searchValue);
      API.graphql(
        graphqlOperation(performHybridSearch, {
          parameters: serialized,
          sessionkey: this.sessionKey,
        }),
      )
        .then((res) => {
          const result = JSON.parse(res.data.performHybridSearch);
          this.users = [];
          this.learn = [];
          this.slides = [];
          if (
            result.statusCode === 200 &&
            result.body.payloads &&
            result.body.payloads.length > 0
          ) {
            if (result.body.payloads.length > 0) {
              this.noResults = false;
            } else {
              this.noResults = true;
            }
            result.body.payloads.forEach((item) => {
              if (item.keytype === 'K_USERDATA') {
                this.users.push({
                  ...item,
                });
              }
              if (item.keytype === 'K_LEARNDATA') {
                this.learn.push({
                  ...item,
                });
              }
              if (item.keytype === 'K_CATEGORY') {
                this.slides.push({
                  ...item,
                });
              }
            });
          }
          this.searchProgress = false;
        })
        .catch((err) => {
          console.log(err);
          this.noResults = true;
          this.searchProgress = false;
        });
    },
  },
  beforeMount() {},
  mounted() {
    this.init(this.$route.query.search);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.user-name {
  font-size: 20px;
}
.user-designation {
  font-size: 14px;
}
.divider {
  border-bottom: 0.1px solid rgba(#7b7b7b, 0.5);
}
.slide-wrapper {
  margin-bottom: 5px;
  .slider-content-wrapper {
    margin-bottom: 5px;
    box-shadow: -2px 2px 25px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .slider-content-wrapper:hover {
    box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
  }
  .image-wrapper img {
    border-radius: 8px;
    padding-top: 5%;
    max-height: 90%;
    max-width: 90%;

    cursor: pointer;
  }
  .image-wrapper {
    border-bottom: 1px solid rgba(211, 211, 211, 0.2);
    cursor: pointer;
  }
  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    .bottom-wrapper-title {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .action-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .favorite {
        cursor: pointer;
      }
      .show-detail {
        cursor: pointer;
      }
      .download {
        cursor: pointer;
      }
      .show-detail {
        margin-right: 15px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
    .trending {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        margin-right: 3px;
      }
      .trending-title {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #68788c;
      }
    }
  }
}
.root {
  .slide-type-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    margin-left: 50px;
    .chipgroup {
      max-width: '90vw';
    }
    .slide-type-wrapper-title {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-right: 8px;
    }
    ::v-deep .v-chip {
      padding: 0 25px !important;
      margin-right: 18px !important;
      border-radius: 40px;
      border: solid 1px #21a7e0;
      background: transparent;
      color: #21a7e0;
      font-weight: 500;
      font-size: 16px;
      height: 35px;
      &.v-chip--active {
        background: #21a7e0;
        color: white !important;
      }
    }
  }
  .not-found-container {
    display: flex;
    justify-content: 'center';
    flex-direction: column;
    margin-top: 200px;
    text-align: center;
  }
  .not-found-descriptions {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: fit-content;
    margin: auto;

    .not-found-border {
      //border-bottom: 1px solid #d8d8d8;
      padding-bottom: 35px;
      margin-bottom: 35px;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .final-not-found-description {
      margin-bottom: 24px;
    }

    button {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: white;
      text-transform: none;
    }
  }
  .spinner-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.percentage {
  color: #21a7e0;
}
</style>
